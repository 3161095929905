import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './Hero.css';


function Hero() {
    return (
            // <div class="row">
                
            //         <div className="containerVid fundo">
            //             <div className="marca-dagua"> <h1>Noticias</h1>
            //             {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
            //             <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
            //             <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
            //            </div>
            //            <img src='images/NotFundo.png'></img>
                        
            //         </div>
            //  </div> 
            <div class="page-heading">
        
            <div class="heading-content">
                <h1>Nouvelles</h1>
            </div>
       
    </div>
    )
}

export default Hero