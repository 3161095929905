import React, { useEffect } from "react"; 
import Aos from "aos";
import "aos/dist/aos.css"
import "./Boxes.css"

const CorpServ = () => {
 useEffect(() => {
     Aos.init({duration: 2000});
 }, []);

    return (
        <div className="App">
            
            <div className="App">
                <div className="grids">
                 <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Injection Moulding</h2>
                            <p>High Quality service of Plastic Injection Moulding Process.</p>
                            <p>With more than 20 Year of experience processing Thermoplastic Materials.</p>
                            <p>Counting with 33 Injection Machines in a range from 20 to 350 tons of campling force.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img  style={{marginTop:'75px'}} src='images/InjPlas.png'></img>
                        </div>
                    </div>

                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/DesenProd.png'></img>
                        </div>
                        <div className="Dividir_col right">
                            <h2>Product Development</h2>
                            <p>A team capable of supporting during product development, giving Improvement Inputs, suggesting Savings and making Feasibility Analysis.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Turn-key Projects</h2>
                            <p>Open to work on a "Turn-key Project" basis, being responsible for all Tooling design and construction until the production of  final Plastic parts, or a simple Tool or Project Transfer from our customers.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/TurnkeyProj.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/PreMont2.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>In-line Assemblies</h2>
                            <p>Able to perform assemblies of componentes like Plastic parts, Metallic Inserts, Die-cuts, and others…</p>
                            <p>Preferably, this is something we do in-line with the Injection Machine.</p>                        
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                        <h2>SLA & FDM 3D Printing</h2>
                            <p>With the SLA and FDM 3D printing technology, we can have more inputs on  the development stage of a product, and also find new solutions for the industrialization of its serial life.</p>                     
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/3DPrinter.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/GOM.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Precise Industrial 3D Metrology – 3D Scanning</h2>
                            <p>Internal 3D scanning give us the knowledge and the tools to provide our customers with strong dimensional reports and also more confidence when troubleshooting.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes"> 
                        <div className="Dividir_col left">
                            <h2>High Performance Polymers</h2>
                            <p>Equiped, Prepared and experienced with the injection of all types of Thermoplastics, including High Performance materials with extreme processing conditions.</p>
                        </div>
                        <div className="Dividir_col right">
                        <img src='images/HighPoly.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/ColorMat.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Colour Matching & Raw Material Testing</h2>
                            <p>We offer to our customer the possibility of testing several materials and colours with our “test plates”.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CorpServ;