
import React from 'react';
import  { Redirect } from 'react-router-dom'



function Natal() {


    
   return (
      <div class="containerHeroNatal">


        <div className="containerVidNatal">
           
            {/*<video src="/videos/PostaldeNatal.mp4" autoPlay loop muted='false' playsInline/>*/
}
            
                            
                            <video src="/videos/PostaldeNatal.mp4" autoPlay loop type="video/mp4" controls="controls" muted="" playsInline/>

        </div>
 </div>
    )
} 


export default Natal;

