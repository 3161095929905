import React, { useState, useEffect } from 'react';
import Pdf from "react-to-pdf";
import './Post.css';

const ref = React.createRef();

const PDF = (props) => {

  const [currentDate, setcurrentDate] = useState('')

  useEffect(() => {
    var date = new Date().getDate() 
    var month = new Date().getMonth() + 1
    var year = new Date().getFullYear()

    setcurrentDate(
      ("0" + date).slice(-2) + '/' + ("0" + month).slice(-2) + '/' + year
    )
  }, [])

  return (
    <>
 
      <div className="Post" ref={ref}>
       <div className="row2">
            <div className="column4">
            <img style={{margin:'35px'}}
                  
            alt=""
            src="/images/LogoPlas.png"
            width="120px"
          
            className="d-inline-block align-top"
          />
          </div>
         <div className="column4">
            <strong style={{margin:'35px'}}>Ficha de Candidatura</strong>
         </div>
         <div className="column4">
            <strong style={{margin:'35px', alignContent:'right'}}>{currentDate}</strong>
         </div>
       </div>

        <p style={{margin:'25px'}}><strong>Nome:</strong> {props.name}</p>
        <p style={{margin:'25px'}}><strong>Nome:</strong> {props.content}</p>
        
      </div>
      <Pdf targetRef={ref} filename="post.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Capture as PDF</button>}
      </Pdf>
    </>
  );
}

export default PDF;