import React from 'react';
import '../../App.css';
import Hero from '../HeroEmpresa'
import Empresa from '../Empresa/Empresa'
import Missao from '../Empresa/Missao'
import MachRang from '../Empresa/MachRang'
import PolitGest from '../Empresa/PolitGest'
import SalesDist from '../Empresa/SalesDist'
import Footer from '../../Footer/Footer';

function Noticias(){
    return (
        <div>
            <Hero/>
            <Empresa/>
            <SalesDist/>
            <Missao/>
            <PolitGest/>
            <Footer/>
            
        </div>
    );
}

export default Noticias;
