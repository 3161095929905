
// import React from 'react';
import React, { useEffect } from "react"; 
import Aos from "aos";
import ImageZoom from 'react-medium-image-zoom'
import "aos/dist/aos.css"
// import '../Noticias/Noticias.css';
// import 'bootstrap/dist/css/bootstrap.css';



const  Noticias = () => {
    useEffect(() => {
        Aos.init({duration: 3000});
    }, []);



    return (
        // <div className="grids">
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        // </div>
        
        <div>
        <div className="row3">
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/05/2024</label>
                                <h3>ISO 14001</h3>
                        </div>        
                        <p>Com foco no Ambiente e num Futuro mais sustentável a Plastifa certificou-se ISO 14001 - Sistema de Gestão Ambiental.</p>
                       
                       
                        
                        <a href="https://www.linkedin.com/feed/update/urn:li:activity:7196080381643616257" width='100%'>
                            <img  src='images/14001.jpeg' width='100%'/>
                        </a>
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2023</label>
                                <h3>Um futuro mais verde</h3>
                        </div>        
                        <p>Com foco na Sustentabilidade e num Futuro mais verde, a Plastifa dá o passo de investir na produção de Energia Solar.</p>
                       
                       
                        
                        <a href="https://www.linkedin.com/posts/plastifa_com-foco-na-sustentabilidade-e-num-futuro-activity-7157008055249821696-QJP5?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/PaineisSolares.JPG' width='100%'/>
                        </a>
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>25/10/2023</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>A empresa foi pelo 4º ano consecutivo reconhecida como parte do <strong>TOP 5% MELHORES PME DE PORTUGAL</strong> ao nível de Desempenho e Solidez Financeira!</p>

                        <ImageZoom
                            image={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão'
                            }}
                        />
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>01/06/2023</label>
                                <h3>Novo Vídeo</h3>
                        </div>        
                        <p>Num mundo impulsionado pelo constante progresso e inovação, a Plastifa tem orgulho em fazer uma breve apresentação da sua em empresa com tecnologia de ponta e ideias inovadoras.</p>
                       <br/>
                       <br/>
                        <a href="https://www.linkedin.com/posts/plastifa_plastifa-your-partner-for-plastic-parts-activity-7059587841172865024-LQ9A?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/NotVideo.png' width='100%'/>
                        </a>
                </div>
            </div>
       
        </div>

        <div className="row3">
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>17/10/2022</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>A empresa foi pelo terceiro ano consecutivo reconhecida como parte do <strong>TOP 5% MELHORES PME DE PORTUGAL</strong> ao nível de Desempenho e Solidez Financeira!</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão'
                            }}
                        />
                </div>
            </div>
        
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2021</label>
                                <h3>Postal de Natal</h3>
                        </div>        
                        <p>Por favor, carregar na imagem em baixo para aceder ao Postal de Natal da Plastifa:</p>
                       
                       
                        
                        <a href="https://plastifa.pt/Natal" width='100%'>
                            <img  src='images/Natal.png' width='100%'/>
                        </a>
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/11/2021</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>A empresa foi pelo segundo ano consecutivo reconhecida como parte do <strong>TOP 5% MELHORES PME DE PORTUGAL</strong> ao nível de Desempenho e Solidez Financeira!</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão'
                            }}
                        />
                </div>
        </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>24/04/2021</label>
                                <h3>PME Excelência</h3>
                        </div>        
                        <p>A Plastifa renova pelo 7 º  ano consecutivo o estatuto PME Excelência</p>
                       
                        <ImageZoom
                            image={{
                                src: 'images/PMEex3.png',
                                alt: 'PME Excelência',
                                className: 'img',
                                style: { width: '30em' }
                                //width:'50em' em todos
                            }}
                            zoomImage={{
                                src: 'images/PMEex3.png',
                                alt: 'PME Excelência'
                            }}
                        />
                </div>
            </div>
       
      
        </div>
        <div className="row3">
        <div className="Dividir_col2">
            <div data-aos="fade-left"  class="container5">
                    
                    <div class="row2">
                            <label>24/12/2020</label>
                           <h3>SCORING TOP 5%</h3>
                    </div>       
                    <p>A empresa foi reconhecida como parte do <strong>TOP 5% MELHORES PME DE PORTUGAL</strong> ao nível de Desempenho e Solidez Financeira!</p>
                 
                    <ImageZoom
                        image={{
                            src: 'images/TOP52020.jpg',
                            alt: 'TOP 5%',
                            className: 'img',
                            style: { width: '20em' }
                         }}
                        zoomImage={{
                            src: 'images/TOP52020.jpg',
                            alt: 'TOP 5%'
                         }}
                    />
            </div>
        </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>16/04/2020</label>
                                <h3>EPI’s Plastifa </h3>
                        </div>        
                        <p>De forma a fazer a sua parte na luta contra a Pandemia Covid 19, a Plastifa dedicou-se ao fabrico de Viseiras e Fixadores para máscaras.</p>
                       
                        <ImageZoom
                            image={{
                                src: 'images/Viseiras.png',
                                alt: 'EPIs PLASTIFA',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/Viseiras.png',
                                alt: 'EPIs PLASTIFA'
                            }}
                        />
                </div>
            </div>

        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>02/10/2020</label>
                                <h3>Expansão a Plastifa Unidade 2 </h3>
                        </div>        
                        <p>A Plastifa avançou para a construção de mais uma nave de 1.000 m2 na Unidade 2.</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/NewInst.jpg',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/NewInst.jpg',
                                alt: 'Expansão'
                            }}
                        />

                        <ImageZoom
                            image={{
                                src: 'images/ExpUn2.jpeg',
                                alt: 'Golden Gate Bridge',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/ExpUn2.jpeg',
                                alt: 'Golden Gate Bridge'
                            }}
                        />
                </div>
            </div>
        </div>
        </div>
    )
}


export default Noticias;