import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './Hero.css';
import ReactPlayer from 'react-player'

function isIOSDevice(){
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
 }


function Hero() {
    // if(isIOSDevice()){
    //     return (
    //         <div class="page-heading">
        
    //         <div class="heading-content">
    //             <h1 style={{marginBottom:'75px'}}>Serviços IPHONE</h1>
               
    //         </div>
       
    // </div>

           
    //     )
    // }
    // else{
        return (
          
    
<div class="row">
                    
<div className="containerVid">
    <div className="marca-dagua"> <h1>Os nossos Serviços</h1>
    {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
    <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
    <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
   </div>
     <video src="/videos/HighLights 19-04-2021.m4v" autoPlay loop muted />
     {/* <ReactPlayer url='/videos/HighLights 19-04-2021.m4v' playing /> */}
    
</div>
</div>
        )

    }
  
// }

export default Hero