import React, { useEffect } from "react"; 
import Aos from "aos";
import "aos/dist/aos.css"
import "./Boxes.css"

const CorpServ = () => {
 useEffect(() => {
     Aos.init({duration: 2000});
 }, []);

    return (
        <div className="App">
            
            <div className="App">
                <div className="grids">
                 <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Kunststoffspritzguss</h2>
                            <p>Spritzgießen von Kunststoffen mit höchsten Qualitätsstandards.</p>
                            <p>Mit mehr als 20 Jahren Erfahrung in der Verarbeitung aller gängigen thermoplastischen Materialien.</p>
                            <p>Im Jahr 2021 besteht unser Maschinenpark aus insgesamt 33 Spritzgießmaschinen mit Leistungen zwischen 20 und 350 Tonnen Schließkraft.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img  style={{marginTop:'75px'}} src='images/InjPlas.png'></img>
                        </div>
                    </div>

                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/DesenProd.png'></img>
                        </div>
                        <div className="Dividir_col right">
                            <h2>Produktentwicklung</h2>
                            <p>Ein Team, das Sie bei der Produktentwicklung mit Verbesserungsvorschlägen, Kostenreduzierungen oder Machbarkeitsanalysen unterstützen kann.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Turnkey-Lösungen</h2>
                            <p>Wir übernehmen die gesamte Konstruktion und den Bau der für die Produktion benötigten Werkzeuge. Alternativ arbeiten wir mit geprüften Werkzeugen, die von unseren Kunden beigestellt werden.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/TurnkeyProj.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/PreMont2.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Vormontage</h2>
                            <p>Wir übernehmen auch die Vormontage von zusätzlichen Komponenten wie z.B. von Kunststoffteilen, Einlegeteilen, Schäumen, Folien.</p>
                            <p>Diesen Vorgang führen wir vorzugsweise in der Fertigungslinie an der Spritzgussmaschine durch.</p>                        
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                        <h2>SLA & FDM 3D-Druck</h2>
                            <p>Mit der SLA- und FDM-3D-Drucktechnologie können wir in der Entwicklungsphase eines Produktes mehr Informationen erhalten und auch geeignetere Lösungen für die Industrialisierung finden.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/3DPrinter.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/GOM.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Präzise industrielle 3D-Messtechnik - 3D-Scannen</h2>
                            <p>Mit eigener 3D-Messtechnik verfügen wir über das Wissen und die Werkzeuge, um unseren Kunden aussagekräftige Maßberichte und mehr Sicherheit bei der Problemlösung zu bieten.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes"> 
                        <div className="Dividir_col left">
                            <h2>Hochleistungspolymere</h2>
                            <p>Wir verfügen über die Ausrüstung und Erfahrung für das Spritzgießen aller gängiger thermoplastischen Kunststoffe, einschließlich Hochleistungspolymere sowie für Verfahren mit extremen Bedingungen.</p>
                        </div>
                        <div className="Dividir_col right">
                        <img src='images/HighPoly.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/ColorMat.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Material- und Farbtestplatten</h2>
                            <p>Wir bieten unseren Kunden die Möglichkeit, verschiedene Materialien und Farben mit unseren "Testplatten" zu testen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CorpServ;