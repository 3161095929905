
import React from 'react';





function EmpregoText() {

    return (
        <div style={{marginTop: '50px'}} class="container7">
                 <div class="row2">
                              <h2>Candidaturas Espontâneas</h2>
                 </div> 
                 <ul>       
                    
                 <p style={{marginLeft: '50px'}}><i style={{marginRight: "20px", marginBottom: "80px"}} class="fas fa-envelope fa-md"> </i>recursos.humanos@plastifa.pt</p>
                   
                 </ul>
        </div>      
    )
}


export default EmpregoText;