
import React from 'react';





function Missao() {

    return (
        <div style={{ textAlign:'center'}} class="container8 margin10">
                 <div class="row" style={{textAlign:'center', justifyContent:'center'}}>
                              <h1 style={{marginTop:'10px', textAlign:'center', justifyContent:'center',color:'#4da4e7'}}>Our Mission</h1>
                 </div>        
                <p style={{fontWeight:'600'}}>"The establishment of long-term partnerships with its customers and suppliers, focusing on Customers Satisfaction through the Quality of its Products and Services, makes Plastifa a company of reference in its sector"</p>       
                <p style={{marginTop:'10px'}}>It is with this mission that we work every day.</p>
                <img src='images/Missao.png'></img>
        </div>      
    )
}


export default Missao;