import React from 'react'
import Contact from '../Contacts/contactEn'
import ContactMail from '../Contacts/contactMailEn'
import HeroContact from '../HeroContactEn';
import Footer from '../../Footer/FooterEn';
import '../../App.css';


function ContactPage () {
    return (
        <div>
           <HeroContact/>
           <Contact/>
           <ContactMail/>
           <Footer />
        </div>
    );
}

export default ContactPage;