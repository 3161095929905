import React from 'react';

function Hero() {
    return (
       <div class="page-heading" style={{marginBottom:'2rem', marginTop:'2rem'}}>

            <figure id="container20">
                
                <img src='images/contracr.jpg'/>
        
                <figcaption  style={{ textShadow:'0px 0px 0px',top:'7rem'}}>Candidaturas a Emprego</figcaption>
            </figure>
               
        </div> 
    )
}

export default Hero