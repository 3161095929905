
import React from 'react';





function SalesDist() {

    return (
        // <div class="container5">
        //          <div class="row4">
        //          <div className='Dividir_col left'>
        //                          <img src='images/DistVendas.png'></img>
        //                      </div>
        //                      <div className='Dividir_col right'>
        //                          <img src='images/DistVend2.gif'></img>
        //                      </div>
        //          </div>        
        // </div>      
       
        <div class="container8">
                <div className="Dividir_col3">
                    <img src='images/MachRang.png'></img>
                    <img src='images/capacidadeMaq.gif'></img>
                </div>   
                <div className="Dividir_col3">
                <img src='images/DistVendas.png'></img>
                <img src='images/DistVend2.gif'></img> 
            </div> 
            </div>
        
        
    )
}


export default SalesDist;