import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';


function NavbarHome() {
    const [click, setclick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setclick(!click);
    const closeMobileMenu = () => setclick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        } else{
            setButton(true);
        }
    };

    useEffect(() =>{showButton();}, [] );

    window.addEventListener('resize', showButton);

    const changeBackground = () => {
        if(window.scrollY >= 900){
            setNavbar(true)
        }
        else{
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    
    return (
        <>
           <nav className={navbar ? 'navbar active' : 'navbar'}>
               <div className="navbar-container">
               <a href='/'>
        <img
        alt=""
        src="/images/LogoPlas.png"
        width="120px"
       
        className="d-inline-block align-top"
      /></a>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Empresa
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Serviços
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/products' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Produtos
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Noticias' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Notícias
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/ContactPage' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Contatos
                        </Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/products' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Emprego
                        </Link>
                    </li> */}
                    <li>
                        <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                            Sign Up
                        </Link>
                    </li>
                </ul>
                {/* {button && <Button buttonStyle='btn--outline'>Sign Up</Button>} */}
               </div>
           </nav>
        </>
    )

}

export default NavbarHome
