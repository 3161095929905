import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './Hero.css';


function Hero() {
    return (
            <div class="row">
                
                    <div className="containerVid">
                        <div className="marca-dagua2"> <h1>Where are we?</h1>
                        {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
                        <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
                        <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
                       </div>
                         <video src="/videos/maps_azul.m4v" autoPlay loop muted />
                        
                    </div>
             </div> 
    )
}

export default Hero