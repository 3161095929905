import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSectionServices'
import Hero from '../HeroServ'
import HeroCorp from '../HeroCorp'
import Footer from '../../Footer/Footer';
import CorpServ from '../../components/Services/CorpServ'


function Services () {
    return (
        <div>
            <Hero/>
            <CorpServ/>
            <Footer/>
        </div>
    );
}


export default Services;
