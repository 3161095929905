
import '../Contacts/contact.css';



function Contact() {

 
    return (
      <div>
      <div className="container">
          <div className="column2">
            <h2>Unidade 1</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5984.374903011592!2d-8.464186874793862!3d41.41344937519914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2457f3ab34eb93%3A0xd5c5906f438cf486!2sPlastifa%20-%20Pl%C3%A1sticos%20T%C3%A9cnicos%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1622027618688!5m2!1spt-PT!2spt" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            <li class="open"> Unidade Produtiva com 25 Máquinas </ li> 
            <li class="open"> Departamentos: </ li> 
            <li class="open marge"> Otimização do Processo </ li> 
            <li class="open marge"> Formação </ li> 
            <li class="open"> Armazém </ li>
            <li class="open"> Manutenção de Ferramentas e Equipamentos </ li>
        </div>
        <div className="column">
           
        </div>
        <div className="column2">
            <h2>Unidade 2</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.345465145687!2d-8.459357184709578!3d41.410018879262196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24f78df06db705%3A0x29cbd94b7b52b239!2sPLASTIFA%20--%20Unidade%202%20-Plant%202!5e0!3m2!1spt-PT!2spt!4v1622027763008!5m2!1spt-PT!2spt" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            
            <li class="open"> Unidade Produtiva com 8 Máquinas </ li> 
            <li class="open"> Recepção </ li> 
            <li class="open"> Departamentos: </ li> 
            <li class="open marge"> Administração </ li> 
            <li class="open marge"> Logística </ li> 
            <li class="open marge"> Financeiro </ li> 
            <li class="open marge"> Comercial </ li> 
            <li class="open marge"> Vendas </ li> 
            <li class="open marge"> Qualidade </ li> 
            <li class="open marge"> Engenharia </ li> 
            <li class="open marge"> Industrialização </ li> 
            <li class="open marge"> Desenvolvimento de Produto </ li> 
            <li class="open"> Armazém </ li>
            <li class="open"> Manutenção de Ferramentas </ li>
        </div>
      </div>
      </div>
    )
}

export default Contact;