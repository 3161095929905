import React from 'react';
import './Footer.css';
import { Button } from '../components/Navbar/Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        { <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> }
      </section> */}
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
        <div class='footer-link-items  margin'>
           <img src='images/FooterPlas.png'></img>
            <h4>Plastifa - Plásticos Técnicos, Lda.</h4>
            <h4>Ihr Partner für Kunststoffteile</h4>
            <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href="https://www.facebook.com/Plastifa-158172807562010/"
              target='_blank'
              aria-label='Facebook'
              style={{fontSize:'25px'}} 
            >
              <i class='fab fa-facebook-f'  />
            </a>
            
            <a 
              class='social-icon-link twitter'
              href="https://pt.linkedin.com/company/plastifa"
              target='_blank'
              aria-label='LinkedIn'
              style={{fontSize:'25px'}} 
            >
              <i class='fab fa-linkedin' />
            </a>
          </div>
        </div>
        <div class='footer-link-items margin2'>
            <h2>Nehmen Sie Kontakt zu uns auf:</h2>
            
       
        </div>
        <div class='footer-link-items width'>
           
            <Link to='/ContactPageDe' style={{paddingTop:'27px'}} >Jobs</Link>
            <Link to='/ContactPageDe'>Andere Kontaktdaten</Link>
          </div>
          <div class='footer-link-items width'>
          
           
            <i class="fas fa-envelope fa-lg"><p> plastifa@plastifa.pt</p> </i>
           
            
            <i class="fas fa-phone-alt fa-lg"><p> +351 252 992 938</p> </i>
            <i class="fas fa-map-marker-alt fa-lg"> <p><p>Av. da Liberdade nº 266</p>
              <p>P- 4770-459 Requião</p>
              <p>PORTUGAL</p></p></i>
            
          </div>
       
        </div>
       
        {/* <div className='footer-link-wrapper'>
          { <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div> }
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
          </div>
        </div> */}
      </div>
      {/* <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              TRVL
              <i class='fab fa-typo3' />
            </Link>
          </div>
          <small class='website-rights'>TRVL © 2020</small>
         
        </div>
      </section> */}
    </div>
  );
}

export default Footer;