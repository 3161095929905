
import React from 'react';





function PolitGest() {

    return (
        <div class="container7" style={{marginBottom:'70px', marginTop:'50px'}}>
                 <div class="row2">
                              <h3>Politique de Gestion</h3>
                 </div>        
                 <p><strong>1. </strong> Dépasser les besoins et attentes de nos clients, en respect aux niveaux de qualité du produit/service et délais de livraison;</p>
                 <p><strong>2. </strong> La manutention en permanence d'un niveau élevé de qualité de nos produits. Comme support, nous recourons à la maîtrise de soi, au travail en groupe et à la philosophie d'Amélioration Continue et de la Productivité, toujours en privilégiant la Prévention à la Détection;</p>
                 <p><strong>3. </strong> Adopter des comportements qui empêchent la Pollution, par intermédiaire de contrôle et minimisation des impacts environnementaux plus significatifs, tels comme la consommation d'énergie, la consommation de ressources et la production de résidus;</p>
                 <p><strong>4. </strong> Assurer l'identification oportune et accomplissement de toutes les éxigences légales aplicables dans le cadre de ses aspects environnementaux, bien comme autres souscrits par l'organisation;</p>
                 <p><strong>5. </strong> L'amélioration continue de l'efficace du Système de Gestion;</p>
                 <p><strong>6. </strong> L'accomplissement, de la part de nos produits et services, des éxigences connues et potencielles de nos clients, également ou mieux que ceux de nos concurrents;</p>
                 <p><strong>7. </strong> Compétence technique elevée, flexibilité et la capacité d'innovation pour la satisfaction des éxigences de nos clients;</p>
                 <p><strong>8. </strong> La capacité de maintenir un bom niveau d'implication et formation de nos collaborateurs, pour que nous ayons le reflet de sa contribution dans l'amélioration de la Qualité et de la Productivité de nos produits;</p>
                 <p><strong>9. </strong> La conservation permanente d'une rélation de coopération et d'amélioration continue avec nos fournisseurs et clients , qui puisse se réfléchir sur la Qualité de nos produits et sur l'amélioration continue de la perfomance environnementale;</p>
                 <p><strong>10. </strong> La concéption et conservation des postes de travail sûrs, de manière à éviter des accidents de travail.</p>
                 <br></br>
                 <p style={{textAlign:'right'}}><strong>16/10/2023 </strong> </p>   
        </div>      
    )
}


export default PolitGest;