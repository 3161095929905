import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSectionFr'
import Cards from '../CardsFr';
import CardsCert from '../Certificados/CardsCertFr';
import Footer from '../../Footer/FooterFr';
import TimeLine from '../Timeline/TimeLineFr';
import Chrono from '../Chrono/chrono';


function Home () {
    return (
        <div>
            <HeroSection/>
            <Cards/>
            <CardsCert />
            <TimeLine />
            <Footer />
        </div>
    );
}

export default Home;