import React from 'react';
import Highlights from '../Services/HighlightsEn'
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSectionServices'
import Hero from '../HeroContEn'
import HeroCorp from '../HeroCorp'
import Footer from '../../Footer/FooterEn';

function ProductsEn(){
    return (
        <div>
            <Hero/>
            <Highlights />
            <Footer/>
{/*            
            <HeroCorp/> */}
            
        </div>
    );
}

export default ProductsEn;
