
import React, { useEffect, useState } from 'react';

function Natal() {

    return (
        <div class="containerHeroNatal">

        <div className="containerVidNatal">
           
           <video src="/videos/Postal-EN.m4v" autoPlay loop muted playsInline/>
           
        </div>
 </div>
    )
}


export default Natal;