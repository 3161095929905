
import React from 'react';





function PolitGest() {

    return (
        <div class="container7" style={{marginBottom:'70px', marginTop:'50px'}}>
                 <div class="row2">
                              <h3>Firmenpolitik</h3>
                 </div>        
                 <p><strong>1. </strong> Die Bedürfnisse und Erwartungen unserer Kunden in Bezug auf Produkt- und Servicequalität und Lieferzeiten übertreffen;</p>
                 <p><strong>2. </strong> Die gleichbleibend hohe Qualität unserer Produkte erhalten. Dazu nutzen wir Eigenkontrolle, Teamwork und die Philosophie der ständigen Verbesserung und Produktivitätssteigerung, wobei die Vermeidung von Fehlern stets vor deren Beseitigung steht;</p>
                 <p><strong>3. </strong> Wir vermeiden Umweltverschmutzung, indem wir die stärksten Umwelteinflüsse wie z. B. den Energieverbrauch, den Verbrauch von Ressourcen und die Erzeugung von Abfällen kontrollieren und minimieren;</p>
                 <p><strong>4. </strong> Die Einhaltung aller geltenden rechtlichen Anforderungen in Bezug auf die Umwelt ist für uns selbstverständlich. Aber nicht nur der gesetzlich vorgegebenen, sondern auch noch unserer eigenen, die wir uns zusätzlich auferlegt haben;</p>
                 <p><strong>5. </strong> Eine kontinuierliche Verbesserung der Effizienz des Managementsystems;</p>
                 <p><strong>6. </strong> Wir erfüllen mit unseren Produkten und Services die bekannten und potenziellen Anforderungen unserer Kunden in gleicher oder besserer Weise als unsere Wettbewerber;</p>
                 <p><strong>7. </strong> Hohe technische Kompetenz, Flexibilität und Innovationsfähigkeit, um die Kundenanforderungen zu erfüllen;</p>
                 <p><strong>8. </strong> Die Fähigkeit, unsere Mitarbeiter gleichbleibend auf hohem Niveau einzubeziehen und zu schulen, damit wir ihren Beitrag zur Verbesserung der Qualität und Produktivität der Produkte nutzen können;</p>
                 <p><strong>9. </strong> Die dauerhafte Aufrechterhaltung einer partnerschaftlichen Zusammenarbeit mit unseren Kunden und Zulieferern ist geprägt von ständiger Verbesserung. Dies spiegelt sich in der Qualität unserer Produkte und der stetigen Verbesserung der Umweltfreundlichkeit des Unternehmens wieder;</p>
                 <p><strong>10. </strong> Die Gestaltung und Erhaltung von sicheren Arbeitsplätzen, um Arbeitsunfälle zu vermeiden.</p>
                 <br></br>
                 <p style={{textAlign:'right'}}><strong>16/10/2023 </strong> </p>   
        </div>      
    )
}


export default PolitGest;