import React from 'react'
import '../../App.css';
import emailjs from "emailjs-com";
import '../Candidaturas/Form.css'
import { Row } from 'react-bootstrap';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';


const Mailer = () => {

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_m5ctqza','template_7j2o0wd',e.target,"user_yIwXd2Qf8GN9h5QrSVgIX").then(res=>{console.log(res);}).catch(err=> console.log(err));
    }

    const options = [
        { value: 'Sim', label: 'Sim', name:"carta" },
        { value: 'Não', label: 'Não', name:"carta" },
      ]

      const animatedComponents = makeAnimated();
      

    return(
        <div className="container8 border" style={{  backgroundColor:'white', backgroundPosition:'Center', backgroundSize:'cover'}}>
            
            <h1>Ficha de Candidatura</h1>
            <form className="row" onSubmit={sendEmail}>
                <div className="row3" >
                    <div className="Dividir_col7">
                        <label>Nome</label>
                        <input type="text" name="name"/>
                    </div>
                </div>

                <div className="row3">
                    <div className="Dividir_col4">
                        <label>Rua</label>
                        <input type="text" name="rua"/>
                    </div>
                    <div className="Dividir_col4">
                        <div className="Dividir_col5">
                            <label>Nº Porta</label>
                            <input type="text" name="n_porta" />
                        </div>
                        <div className="Dividir_col5">
                            <label>Andar</label>
                            <input type="text" name="andar" />
                        </div>
                        <div className="Dividir_col5">
                            <label>Código Postal</label>
                            <input type="text" name="c_postal" />
                        </div>
                    </div>
                </div>
                <div className="row3" >
                    <div className="Dividir_col4">
                        <div className="Dividir_col4">
                            <label>Telemóvel</label>
                            <input type="text" name="telemovel"/>
                        </div>
                    

                        <div className="Dividir_col4">
                            <label>Telefone</label>
                            <input type="text" name="telefone" />
                        </div>
                        </div>
                        <div className="Dividir_col4">
                    
                            <label>Email</label>
                            <input type="text" name="user_email" />
                
                        </div>
                </div>
                <div className="row3">
                        <div className="Dividir_col5">
                            <label>Nacionalidade</label>
                            <input type="text" name="nacio" />
                        </div>
                        <div className="Dividir_col5">
                            <label>Estado Civil</label>
                            <input type="text" name="e_civil" />
                        </div>
                        <div className="Dividir_col5">
                            <label>Data de Nascimento</label>
                            <input type="text" name="d_nascimento" />
                        </div>
                </div>
                <div className="row3">
                        <div className="Dividir_col4">
                            <label>Carta de Condução</label>
                            {/* <input type="text" name="carta" /> */}
                            <Select options={options} placeholder={"Selecionar"} name="carta" style={{marginTop:"7px", marginBottom:"15px"}}/>
                        </div>
                        <div className="Dividir_col4">
                            <label>Categoria</label>
                            <input type="text" name="c_categoria" />
                        </div>
                </div>
                <div className="row3">  
                        <div className="Dividir_col7">
                            <label>Habilitações</label>
                            <input type="text" name="habilitacoes" />
                        </div>
                </div>
                <div className="row3">  
                        <div className="Dividir_col7">
                            <label>Experiência Profissional</label>
                            <input type="text" name="e_profissional" />
                        </div>
                </div>
                <div className="row3">  
                        <div className="Dividir_col7">
                            {/* <label>Conhecimentos Linguísticos</label>
                            <input type="text" name="c_linguas" /> */}


                        </div>
                </div>
                <div className="row3">  
                        <div className="Dividir_col7">
                            <label>Última Entidade Patronal</label>
                            <input type="text" name="u_patronal" />
                        </div>
                </div>
                <div className="row3">  
                        <div className="Dividir_col7">
                            <label>Outros</label>
                            <input type="text" name="outros" />
                        </div>
                </div>
                <div className="row3" style={{alignContent:"right", justifyContent:"right"}}>  
                        <div className="Dividir_col5" >
                        <input type='submit' value='Send' />
                        </div>
                </div>

             
            </form>

        </div>
    );
};

export default Mailer;