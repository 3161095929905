import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './HeroSection.css';
import { isIPhone13 } from 'react-device-detect';

function HeroSection() {

    if(navigator.platform.match(/iPhone|iPad/)){
            return ( 
                
        <div class="containerHero">
                <div class="row"> 
                <div className="">
                    <div className="marca-dagua5"> 
                    <h1>Plásticos Técnicos, Lda.</h1>
                     <p>O seu Parceiro para Peças Plásticas</p>
                    {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
                    <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
                    <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
                   </div>
                   
                   <img src='images/FundoPrincipal.JPG' ></img>
                </div>
         </div>
         </div>
        
        
        
            )
        }
    return (
        <div class="containerHero">
        <div class="row"> 
        <div className="containerVid2">
            <div className="marca-dagua4"> 
            <h1>Plásticos Técnicos, Lda.</h1>
            <p>O seu Parceiro para Peças Plásticas</p>
            {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
            <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
            <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
        </div>
            <video autoPlay loop muted playsinline src="/videos/Exterior.m4v" />
        </div>
</div>
</div>

    )
}

export default HeroSection
