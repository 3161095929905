
import '../Contacts/contact.css';



function Contact() {

 
    return (
      <div>
      <div className="container">
          <div className="column2">
            <h2>Plant 1</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5984.374903011592!2d-8.464186874793862!3d41.41344937519914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2457f3ab34eb93%3A0xd5c5906f438cf486!2sPlastifa%20-%20Pl%C3%A1sticos%20T%C3%A9cnicos%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1622027618688!5m2!1spt-PT!2spt" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            <li class="open"> Production Plant with 25 Machines </ li> 
            <li class="open"> Departments: </ li> 
            <li class="open marge"> Process Optimization </ li> 
            <li class="open marge"> Training </ li> 
            <li class="open"> Warehouse </ li>
            <li class="open"> Tools and Equipment Maintenance</ li>
        </div>
        <div className="column">
           
        </div>
        <div className="column2">
            <h2>Plant 2</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.345465145687!2d-8.459357184709578!3d41.410018879262196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24f78df06db705%3A0x29cbd94b7b52b239!2sPLASTIFA%20--%20Unidade%202%20-Plant%202!5e0!3m2!1spt-PT!2spt!4v1622027763008!5m2!1spt-PT!2spt" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            <li class="open"> Production Plant with 8 Machines </ li> 
            <li class="open"> Reception </ li> 
            <li class="open"> Departments: </ li> 
            <li class="open marge"> Management </ li> 
            <li class="open marge"> Logistics </ li> 
            <li class="open marge"> Financial </ li> 
            <li class="open marge"> Sales </ li> 
            <li class="open marge"> Quality </ li> 
            <li class="open marge"> Engineering </ li> 
            <li class="open marge"> Industrialization </ li> 
            <li class="open marge"> Product development </ li> 
            <li class="open marge"> Commercial </ li> 
            <li class="open"> Warehouse </ li>
            <li class="open"> Tools Maintenance  </ li>
        </div>
      </div>
      </div>
    )
}

export default Contact;