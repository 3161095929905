import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSectionServices'
import Hero from '../HeroServDe'
import HeroCorp from '../HeroCorp'
import Footer from '../../Footer/FooterDe';
import CorpServ from '../Services/CorpServDe'


function Services () {
    return (
        <div>
            <Hero/>
            <CorpServ/>
            <Footer/>
        </div>
    );
}


export default Services;
