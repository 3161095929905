import React from 'react'; 
import Navbar from '../src/components/Navbar/Navbar'
import NavbarEn from '../src/components/Navbar/NavbarEn'
import NavbarFr from '../src/components/Navbar/NavbarFr'
import NavbarDE from '../src/components/Navbar/NavbarDE'
import NavbarHome from './components/Navbar/NavbarHome'
import {BrowserRouter as Router, Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home'
import HomeDE from './components/pages/HomeDE'
import HomeEn from './components/pages/HomeEn'
import HomeFr from './components/pages/HomeFr'
import Empresa from './components/pages/Empresa'
import EmpresaDE from './components/pages/EmpresaDe'
import EmpresaEn from './components/pages/EmpresaEn'
import EmpresaFr from './components/pages/EmpresaFr'
import Products from './components/pages/Products';
import ProductsDE from './components/pages/ProductsDe';
import ProductsEn from './components/pages/ProductsEn';
import ProductsFr from './components/pages/ProductsFr';
import Services from './components/pages/Services';
import servicesDE from './components/pages/ServicesDe';
import ServicesEn from './components/pages/ServicesEn';
import ServicesFr from './components/pages/ServicesFr';
import ContactPage from './components/pages/ContactPage';
import ContactPageDE from './components/pages/ContactPageDe';
import ContactPageen from './components/pages/ContactPageEn';
import ContactPageFr from './components/pages/ContactPageFr';
import Noticias from './components/pages/Noticias';
import NoticiasDE from './components/pages/NoticiasDE';
import NoticiasEn from './components/pages/NoticiasEn';
import NoticiasFr from './components/pages/NoticiasFr';
import SignUp from './components/pages/SignUp';
import Natal from './components/Postal/Natal';
import Christmas from './components/Postal/Natal-EN';
import './components/PDF/css/estilos.css';
import ScrollToTop from './components/ScrollToTop'
import Mailersend from './components/Mail/Mailersend';
import PDFSender from './components/PDFCreate/Post';
import Sendfiles from './components/Sendfiles/Send';
import Recrut from './components/Candidaturas/Recrut'
import Emprego from './components/Emprego/Emprego';
import Upload from './components/Upload/UploadPage';

function App(){

  return(

    
    <div>
      <>
      {/* <Router>
      <ScrollToTop/>
      < Navbar />
        <Switch>
        <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/sign-up' component={SignUp} />
        </Switch> 
      </Router>  */}
        <Router>
          
        <ScrollToTop/>
        <Route exact path="/Products">
            < Navbar  />
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
            <Route exact path="/videos" forceRefresh={true}>
            < Navbar  />
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
            <Route exact path="/UploadPage">
            < Navbar  />
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
            <Route exact path="/Emprego">
            < Navbar  />
              <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
          <Route exact path="/services">
            < Navbar  />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
            <Route exact path="/Natal">
            
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />

              </Switch> 
            </Route>
            <Route exact path="/Christmas">
            
            <Switch>
            <Route path='/' exact component={Home} />
              <Route path='/Empresa' component={Empresa} />
              <Route path='/products' component={Products} />
              <Route path='/services' component={Services} />
              <Route path='/ContactPage' component={ContactPage} />
              <Route path='/Noticias' component={Noticias} />
              <Route path='/Natal' component={Natal} />
              <Route path='/Christmas' component={Christmas} />
              <Route path='/Recrut' component={Recrut} />
              <Route path='/Emprego' component={Emprego} />
              <Route path='/UploadPage' component={Upload} />

            </Switch> 
          </Route>
            <Route exact path="/ContactPage">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Mailersend' component={Mailersend} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route>
          <Route exact path="/Noticias">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route>
          <Route exact path="/Empresa">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/productsen">
            < NavbarEn />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
                <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/ContactPageen">
            < NavbarEn />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
              <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/HomeEn">
            < NavbarEn />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
              <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/NoticiasEn">
            < NavbarEn />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
              <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/EmpresaEn">
            < NavbarEn />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
              <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/servicesEn">
            < NavbarEn  />
              <Switch>
              <Route path='/HomeEn' exact component={HomeEn} />
              <Route path='/Empresaen' component={EmpresaEn} />
                <Route path='/productsen' component={ProductsEn} />
                <Route path='/servicesEn' component={ServicesEn} />
                <Route path='/ContactPageen' component={ContactPageen} />
                <Route path='/NoticiasEn' component={NoticiasEn} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/productsFr">
            < NavbarFr />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
                <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/ContactPageFr">
            < NavbarFr />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
              <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/HomeFr">
            < NavbarFr />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
              <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/NoticiasFr">
            < NavbarFr />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
              <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/EmpresaFr">
            < NavbarFr />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
              <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/servicesFr">
            < NavbarFr  />
              <Switch>
              <Route path='/HomeFr' exact component={HomeFr} />
              <Route path='/EmpresaFr' component={EmpresaFr} />
                <Route path='/productsFr' component={ProductsFr} />
                <Route path='/servicesFr' component={ServicesFr} />
                <Route path='/ContactPageFr' component={ContactPageFr} />
                <Route path='/NoticiasFr' component={NoticiasFr} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/ProductsDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/servicesDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/ContactPageDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/NoticiasDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/EmpresaDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/Mailersend' component={Mailersend} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/HomeDE">
            < NavbarDE  />
              <Switch>
                <Route path='/HomeDE' exact component={HomeDE} />
                <Route path='/EmpresaDE' component={EmpresaDE} />
                <Route path='/productsDE' component={ProductsDE} />
                <Route path='/servicesDE' component={servicesDE} />
                <Route path='/ContactPageDE' component={ContactPageDE} />
                <Route path='/NoticiasDE' component={NoticiasDE} />
                <Route path='/Mailersend' component={Mailersend} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
            </Route>
            <Route exact path="/Mailersend">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Mailersend' component={Mailersend} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
          <Route exact path="/PDFSender">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/PDFSender' component={PDFSender} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route>
          <Route exact path="/Recrut">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/PDFSender' component={PDFSender} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route>
          <Route exact path="/Sendfiles">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Sendfiles' component={Sendfiles} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
            <Route exact path="/">
            < Navbar />
              <Switch>
              <Route path='/' exact component={Home} />
                <Route path='/Empresa' component={Empresa} />
                <Route path='/products' component={Products} />
                <Route path='/services' component={Services} />
                <Route path='/ContactPage' component={ContactPage} />
                <Route path='/Noticias' component={Noticias} />
                <Route path='/Mailersend' component={Mailersend} />
                <Route path='/Natal' component={Natal} />
                <Route path='/Christmas' component={Christmas} />
                <Route path='/Recrut' component={Recrut} />
                <Route path='/Emprego' component={Emprego} />
                <Route path='/UploadPage' component={Upload} />
              </Switch> 
          </Route> 
  </Router>

      

      </>
    </div>
  )
}


export default App;