import React from 'react';
import './Hero.css';


function Hero() {
    return (
            <div class="page-heading">

            <div class="row">
            <a href="https://www.linkedin.com/posts/plastifa_plastifa-your-partner-for-plastic-parts-activity-7059587841172865024-LQ9A?utm_source=share&utm_medium=member_desktop">
                <div className="containerVid">
                    {/* <div className="marca-dagua2"> <h1>Onde Estamos?</h1>
                    {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
                    <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
                    <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> }
                   </div> */}
                   {/* <div className="marca-daguaemp"> 
                   <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=54us1X_wa5s">
                    <img src="images/play.png"></img>
                     </a>
                   </div> */}
                  
                     <video src="/videos/EmpresaCom.m4v" autoPlay loop muted />
                    
                </div>
                </a>
             </div> 
        
            {/* <div class="heading-content">
                <h1 style={{marginBottom:'75px'}}>O seu Parceiro para Peças Plásticas</h1>
               
            </div> */}
       <br></br>
       <br></br>
       <br></br>
    </div>
    

    )
}

export default Hero