
import React from 'react';





function Missao() {

    return (
        <div style={{ textAlign:'center'}} class="container8 margin10">
                 <div class="row" style={{textAlign:'center', justifyContent:'center'}}>
                              <h1 style={{marginTop:'10px', textAlign:'center', justifyContent:'center',color:'#4da4e7'}}>Notre Mission</h1>
                 </div>        
                <p style={{fontWeight:'600'}}>“La création de partenariat de longue date avec ses clients et fournisseurs concentrée sur la satisfaction de ses clients à travers de la qualité de ses produits et services, font de Plastifa une entreprise de référence dans son secteur.”</p>       
                <p style={{marginTop:'10px'}}>C'est avec cette mission que nous travaillons chaque jour.</p>
                <img src='images/Missao.png'></img>
        </div>      
    )
}


export default Missao;