import React from 'react';
import Slider from 'react-slick';
import Card from  './HighCard';
import './Highlights.css';

class Highlights extends React.Component{
  state = {
    data: []
  }

myRef = React.createRef();

  getData = async ()=>{
    const res = await fetch('data.json');
    const data = await res.json();
    this.setState({data : data}) 
  }

  componentDidMount(){
    this.getData();

  }

  prevClick = () =>{
    const slide = this.myRef.current;
    slide.scrollLeft -= slide.offsetWidth;
    if(slide.scrollLeft <= 0){
      slide.scrollLeft = slide.scrollWidth;
    }
  }

  nextClick = () =>{
    const slide = this.myRef.current;
    slide.scrollLeft += slide.offsetWidth;
    if(slide.scrollLeft >= (slide.scrollWidth - slide.offsetWidth)){
      slide.scrollLeft = 0;
    }
  }

  render(){
    const {data} = this.state;
    return(
      <div className="cards__body">
        <div className="wrapper">
        {/* <card ref={this.myRef}>
          <div className="Dividir_col">
             <img src="images/TranslucidParts.PNG" alt="" />
          </div>
          <div className="Dividir_col">
             <img src="images/TranslucidParts.PNG" alt="" />
          </div>
          </card>
          <card ref={this.myRef}>
          <div className="Dividir_col">
             <img src="images/TranslucidParts.PNG" alt="" />
          </div>
          <div className="Dividir_col">
             <img src="images/TranslucidParts.PNG" alt="" />
          </div>
          </card> */}
          <div className="app" ref={this.myRef}>
            <Card data={data} path={this.myRef}/>
          </div>

          <div className="row">
            <div className="prev" onClick={this.prevClick}>
              {/* <img src="images/Prev.PNG" alt="" /> */}
              <i class='fas fa-arrow-left 2x' />
            </div>
            <div className="next" onClick={this.nextClick}>
              <i class='fas fa-arrow-right 5x' />
            </div>
          </div>
        </div>
        </div>
    );
  };
}


export default Highlights;
