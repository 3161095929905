
import React from 'react';





function Empresa() {

    return (
        <div class="container7">
                 <div class="row2">
                              <h3>Who are we?</h3>
                 </div>        
                 <p>Plastifa is a company with over 20 years of experience in the production of plastic injected parts. </p>
                 <p>Focused on the quality of its products and services, it has a team of around 90 people, prepared to respond to the most demanding needs of the market. With around 85% of its production dedicated to the automotive sector and the remaining 15% divided into different areas, among them: Medical, Optical and Sanitary.</p>
                 <p>In its production floor, the company currently has 33 injection machines, prepared to process all types of thermoplastic materials. Besides conventional injection, the techniques of overmolding, bi-injection, vertical injection and the strong experience in extremely technical and visual parts also stand out.</p>
                 <p>Plastifa is proud to renew its "PME Excellence status" in 2022 for the 8th consecutive year.</p>
        </div>      
    )
}


export default Empresa;