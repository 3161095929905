
import React from 'react';





function Empresa() {

    return (
        <div class="container7">
                 <div class="row2">
                              <h3>Das Unternehmen Plastifa</h3>
                 </div>        
                 <p>Seit mehr als 20 Jahren fertigt das Unternehmen Plastifa Kunststoffteile im Spritzgießverfahren.</p>
                 <p>Mit unserem Team von gut 90 engagierten Mitarbeitern legen wir einen besonderen Wert auf die Produkt- und Servicequalität und sind bereit, jederzeit auf die anspruchsvollsten Anforderungen des Marktes einzugehen. Während etwa 85 % unserer Produkte für die Automobilbranche gefertigt werden, verteilen sich die übrigen 15 % auf verschiedene Bereiche wie z. B. Medizin, Optik und Sanitär.</p>
                 <p>Mit unserem Maschinenpark verfügen wir über 33 Spritzgießmaschinen für die Verarbeitung aller gängigen thermoplastischen Werkstoffe. Neben dem konventionellen Spritzgießen haben wir uns auf das Umspritzen von Einlegeteilen, Mehrkomponenten-Spritzgießen und vertikales Spritzgießen spezialisiert und zeichnen uns durch umfassende Erfahrung in der Fertigung von technisch anspruchsvollen Teilen und Sichtteilen aus.</p>
                 <p>Plastifa ist stolz darauf, im Jahr 2022 zum achten Mal in Folge den PME Excellence Award erhalten zu haben.</p>
        </div>      
    )
}


export default Empresa;