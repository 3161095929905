import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';


function Navbar() {
    const [click, setclick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setclick(!click);
    const closeMobileMenu = () => setclick(false);

    const showButton = () => {
        if(window.innerWidth <= 0){
            setButton(false);
        } else{
            setButton(true);
        }
    };

    useEffect(() =>{showButton();}, [] );

    window.addEventListener('resize', showButton);

    const changeBackground = () => {
        if(window.scrollY >= 0){
            setNavbar(true)
        }
        else{
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    
    return (
        <>
           <nav className='navbar active'>
               <div className="navbar-container">
               <a href="/">
               <img
               
        alt=""
        src="/images/LogoPlas.png"
        width="120px"
       
        className="d-inline-block align-top"
      /></a>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/Empresa' className='nav-links active' onClick={closeMobileMenu}>
                            Empresa
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className='nav-links active' onClick={closeMobileMenu}>
                            Serviços
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/products' className='nav-links active' onClick={closeMobileMenu}>
                            Produtos
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Noticias' className='nav-links active' onClick={closeMobileMenu}>
                            Notícias
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Emprego' className='nav-links active' onClick={closeMobileMenu}>
                            Emprego
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <Link to='/ContactPage' className='nav-links active' onClick={closeMobileMenu}>
                            Contatos
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                    
                        <div class="dropdown ">
                           {/* <button class="dropbtn"> <i class='fas fa-globe'  />  */}
                           <button class="dropbtn"><img style={{maxBlockSize:'25%'}} src='images/portugal.png'></img>
                           <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="dropdown-content">
                            <a href="#"> <img src='images/portugal.png'></img> Português</a>
                            <a href="/HomeEn"><img src='images/lingua-inglesa.png'></img> English</a>
                            <a href="/HomeFr"><img src='images/france.png'></img> Français</a>
                            <a href="/HomeDE"><img src='images/alemao.png'></img> Deutsch</a>
                            </div>
                        </div> 
                    
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/products' className='nav-links active' onClick={closeMobileMenu}>
                            Emprego
                        </Link>
                    </li> */}
                </ul>
                {/* {button && <Button buttonStyle='btn--outline'>Sign Up</Button>} */}
               </div>
           </nav>
        </>
    )

}

export default Navbar
