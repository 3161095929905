import React from 'react';
import '../../App.css';
import Hero from '../HeroServFr'
import Footer from '../../Footer/FooterFr';
import CorpServ from '../Services/CorpServFr'


function Services () {
    return (
        <div>
            <Hero/>
            <CorpServ/>
            <Footer/>
        </div>
    );
}

export default Services;
