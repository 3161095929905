import React, { Component } from "react";
import axios from 'axios'

class Send extends Component {

  state = {
    file: null
  }

  handleFile(e){

    let file = e.target.files[0]

    this.setState({file: e})

  }

  handleUpload(e){
    
    let file = this.state.file

    let formdata = new FormData()

    formdata.append('image', file)
    formdata.append('name', "Francisco Rocha")

    axios({
      url: '/some/api',
      method: "POST",
      headers:{
        authori: 'your token'
      },
      data: formdata
    }).then((res)=>{

    })
  }


  render(){
    return (
      <div className="App">
        
        <h1>THE FORM</h1>

        <form>

          <div className="">
            <label>Select File</label>
            <input type="file" name="file" onClick={(e)=>this.handleUpload(e)} />
              
          </div>

          <br />
          <button type="button">Upload</button>
        </form>


      </div>
    )
  }
}

export default Send;