
import React from 'react';





function Missao() {

    return (
        <div style={{ textAlign:'center', justifyContent:'center'}} class="container8 margin10">
                 <div class="row" style={{textAlign:'center', justifyContent:'center'}}>
                              <h1 style={{marginTop:'10px', textAlign:'center', justifyContent:'center',color:'#4da4e7'}}>Unsere Mission</h1>
                 </div>        
                <p style={{fontWeight:'600'}}>„Die Schaffung langfristiger Partnerschaften mit Kunden und Zulieferern mit dem Fokus auf Kundenzufriedenheit durch die Qualität von Produkten und Services machen Plastifa zu einem führenden Unternehmen der Branche.“</p>       
                <p style={{marginTop:'10px'}}>Mit dieser Mission arbeiten wir jeden Tag.</p>
                <img src='images/Missao.png'></img>
        </div>      
    )
}


export default Missao;