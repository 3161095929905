import React, { useEffect } from "react"; 
import Aos from "aos";
import "aos/dist/aos.css"
import "./Boxes.css"

const CorpServ = () => {
 useEffect(() => {
     Aos.init({duration: 2000});
 }, []);

    return (
        <div className="App">
            
            <div className="App">
                <div className="grids">
                 <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Injection Plastique</h2>
                            <p>Le service d'Injection Plastique aves les plus hautes garanties de qualité.</p>
                            <p>Avec plus de 20 années d'expérience dans le procès de tout type de Matériaux Thermoplastiques.</p>
                            <p>Nous comptons en 2021 avec un parc de 33 machines d'injection avec des capacités entre les 20 e les 350 Tonnes de force de fermeture.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img  style={{marginTop:'75px'}} src='images/InjPlas.png'></img>
                        </div>
                    </div>

                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/DesenProd.png'></img>
                        </div>
                        <div className="Dividir_col right">
                            <h2>Développement du produit</h2>
                            <p>Une équipe capable de soutenir sur le développement de produit, suggestion d'améliorations, réduction de coûts et analyses de faisabilité.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Projets Clé-En-Main</h2>
                            <p>Nous pouvons travailler sur un concept Clé-En-Main étant  responsables par tout le project et construction des outils nécessaires pour la production du produit final, ou travailler avec des outils transferés dejà validés par nos clients.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/TurnkeyProj.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/PreMont2.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Pré-Assemblements</h2>
                            <p>Capables de réaliser des pré-assemblements d'autres composants comme pièces plastiques,inserts, mousses, entre autres.</p>
                            <p>Nous réalisons cette opération de préférence en ligne avec la machine d'injection.</p>                        
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                        <h2>Impression 3D par SLA & FDM</h2>
                            <p>Avec la technologie d'impression 3D par SLA et FDM, nous sommes capables d'obtenir plus d'information pendant la phase de développement des produits, ainsi que de trouver les solutions les plus appropriées pour l'industrialisation de sa vie en série.</p>                     
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/3DPrinter.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/GOM.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Métrologie 3D - Scanner 3D</h2>
                            <p>La possibilité de faire le scan 3D internement des pièces nous permet de disposer des informations et des outils nécessaires pour fournir à nos clients des rapports dimensionnels solides, et de résoudre les problèmes avec plus d'assurance.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes"> 
                        <div className="Dividir_col left">
                            <h2>Polymères d'Haute Perfomance</h2>
                            <p>Equipés, préparés et experimentés pour l'injection de tout type de thermoplastiques, incluant matériaux d'haute perfomance et avec des conditions de traitement extrêmes.</p>
                        </div>
                        <div className="Dividir_col right">
                        <img src='images/HighPoly.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/ColorMat.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Plaques d'essai des matériaux et des couleurs</h2>
                            <p>Nous offrons à nos clients la possibilité de tester différents types de matériaux et de couleurs grâce à nos "plaques d'essai".</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CorpServ;