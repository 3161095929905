import React from 'react';
import Highlights from '../Services/Highlights'
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSectionServices'
import Hero from '../HeroNoticiasEn'
import NoticiasN from '../Noticias/NoticiasEn'
import HeroCorp from '../HeroCorp'
import Footer from '../../Footer/FooterEn';

function Noticias(){
    return (
        <div>
            <Hero/>
            <NoticiasN/>
            <Footer/>
{/*            
            <HeroCorp/> */}
            
        </div>
    );
}

export default Noticias;
