
import React from 'react';





function Missao() {

    return (
        <div style={{ textAlign:'center', justifyContent:'center'}} class="container8 margin10">
                 <div class="row" style={{textAlign:'center', justifyContent:'center'}}>
                              <h1 style={{marginTop:'10px', textAlign:'center', justifyContent:'center',color:'#4da4e7'}}>A Nossa Missão</h1>
                 </div>        
                <p style={{fontWeight:'600'}}>“A criação de parcerias de longa data com os seus clientes e fornecedores com foco na satisfação dos seus clientes através da qualidade dos seus produtos e serviços, fazem da Plastifa uma empresa de referência no seu sector.”</p>       
                <p style={{marginTop:'10px'}}>É com esta missão que trabalhamos todos os dias. </p>
                <img src='images/Missao.png'></img>
        </div>      
    )
}


export default Missao;