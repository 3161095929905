import '../Contacts/contact.css';
import { useState, useEffect } from "react";
import {isMobile} from 'react-device-detect';


function contactMail() {
    
    if(isMobile){
        return(
            <div className="column5">
            <h1>Os Nossos Contactos:</h1>
                <div className="back">
                    <h3 className="marg"><strong>Comercial: </strong> </h3>
                    <h3><i class="fas fa-envelope fa-md"> </i>comercial@plastifa.pt</h3>
                    <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3>
                </div>
                <div className="back">
                    <h3 className="marg"><strong>Recursos Humanos: </strong> </h3>
                    <h3><i class="fas fa-envelope fa-md"> </i>recursos.humanos@plastifa.pt</h3>
                    <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3>
                </div>
                <div className="back">
                    <h3 className="marg"><strong>Geral: </strong> </h3>
                    <h3><i class="fas fa-envelope fa-md"> </i>plastifa@plastifa.pt</h3>
                    <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3>
                </div>
            </div>
        )
    }

    else{
    return(

        <div className="container4">
           
                <h1>Os Nossos Contactos:</h1>
            
            <div class="grid-container">
                <div class="item1"> <h3><i class="fas fa-map-marker-alt fa-md"></i>R. de Regadas 140 <p>P-4770-459 Requião</p><p>(Unidade 1) </p> </h3></div>
                <div class="item2"><h3><strong>Comercial: </strong> </h3></div>
                <div class="item4"> <h3><strong>Recursos Humanos: </strong></h3></div>
                <div class="item6"><h3><strong>Geral: </strong></h3></div>
                <div class="item3"><h3><i class="fas fa-envelope fa-md"> </i>comercial@plastifa.pt</h3></div>  
                <div class="item5"><h3><i class="fas fa-envelope fa-md"> </i>recursos.humanos@plastifa.pt</h3></div>
                <div class="item3"><h3><i class="fas fa-envelope fa-md"> </i>plastifa@plastifa.pt</h3></div>  
                <div class="item8"> <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3></div>
                <div class="item9"> <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3></div>
                <div class="item8"> <h3><i class="fas fa-phone fa-md"> </i> +351 252 992 938 (Chamada para rede fixa nacional)</h3></div>
                <div class="item11"> <h3><i class="fas fa-map-marker-alt fa-md"></i> Avenida da Liberdade Nr. 266 <p> P-4770-459 Requião</p><p>(Unidade 2)</p>    </h3></div>

                <div class="item11"> <h3><i class="fas fa-map-marker-alt fa-md"></i> Avenida da Liberdade Nr. 266 <p> P-4770-459 Requião</p><p>(Unidade 2)</p>    </h3></div>
            </div>
        </div>

        // <div className="container2">
        //     <div className="column"></div>
        //     <div className="column">
        //         <div className="teste">
        //         <i class="fas fa-envelope fa-4x"> </i>
        //         <h2>Contacto via e-mail:</h2>
        //         <h3><strong>Comercial: </strong> </h3>
        //         <h3><li>comercial@plastifa.pt</li></h3>
        //         <h3><strong>Recursos Humanos: </strong></h3>
        //         <h3><li>recursos.humanos@plastifa.pt</li></h3>
        //         <h3><strong>Geral: </strong></h3>
        //         <h3><li>plastifa@plastifa.pt</li></h3>
        //         </div>
        //     </div>
        //     <div className="column">
        //         <div className="teste">
        //         <i class="fas fa-envelope fa-4x"> </i>
        //         <h2>Contacto via e-mail:</h2>
        //         <h3><strong>Comercial: </strong> </h3>
        //         <h3><li>comercial@plastifa.pt</li></h3>
        //         <h3><strong>Recursos Humanos: </strong></h3>
        //         <h3><li>recursos.humanos@plastifa.pt</li></h3>
        //         <h3><strong>Geral: </strong></h3>
        //         <h3><li>plastifa@plastifa.pt</li></h3>
        //         </div>
        //     </div>
        //     <div className="column"></div>
        // </div>
    )
    }
}

export default contactMail;