
// import React from 'react';
import React, { useEffect } from "react"; 
import Aos from "aos";
import ImageZoom from 'react-medium-image-zoom'
import "aos/dist/aos.css"
// import '../Noticias/Noticias.css';
// import 'bootstrap/dist/css/bootstrap.css';



const  Noticias = () => {
    useEffect(() => {
        Aos.init({duration: 3000});
    }, []);



    return (
        // <div className="grids">
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        // </div>
        <div>
            <div className="row3">
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/05/2024</label>
                                <h3>ISO 14001</h3>
                        </div>        
                        <p>En mettant l’accent sur l’environnement et un avenir plus durable, Plastifa a été certifié ISO 14001 - Système de gestion environnementale.</p>
                        
                        <a href="https://www.linkedin.com/feed/update/urn:li:activity:7196080381643616257" width='100%'>
                            <img  src='images/14001.jpeg' width='100%'/>
                        </a>
                        
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2023</label>
                                <h3>Un avenir plus vert</h3>
                        </div>        
                        <p>Dans un souci de durabilité et d'un avenir plus vert, Plastifa investit dans la production d'énergie solaire.</p>
                       
                       
                        
                        <a href="https://www.linkedin.com/posts/plastifa_com-foco-na-sustentabilidade-e-num-futuro-activity-7157008055249821696-QJP5?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/PaineisSolares.JPG' width='100%'/>
                        </a>
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>25/10/2023</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>Pour la quatrième année consécutive, l'entreprise a été reconnue dans le <strong>TOP 5% des MEILLEURES PME AU PORTUGAL</strong> en termes de Performance et de Solidité Financière !</p>

                        <ImageZoom
                            image={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão'
                            }}
                        />
                        
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>01/06/2023</label>
                                <h3>Nouveau Vidéo</h3>
                        </div>        
                        <p>Dans un monde conduit par le constant progrès et innovation, Plastifa est fière de vous faire une brève présentation de son entreprise avec technologies de pointe et idées innovantes.</p>
                       <br/>
                       <br/>
                        <a href="https://www.linkedin.com/posts/plastifa_plastifa-your-partner-for-plastic-parts-activity-7059587841172865024-LQ9A?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/NotVideo.png' width='100%'/>
                        </a>
                </div>
            </div>
 
            </div>

            <div className="row3">
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>17/10/2022</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>Pour la troisième année consécutive, l'entreprise a été reconnue dans le <strong>TOP 5% des MEILLEURES PME AU PORTUGAL</strong> en termes de Performance et de Solidité Financière !</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão'
                            }}
                        />
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2021</label>
                                <h3>Christmas Postcard </h3>
                        </div>        
                        <p>Please click on the image bellow to access Plastifa Postcard:</p>
                       
                       
                        
                        <a href="https://plastifa.pt/Christmas" width='100%'>
                            <img  src='images/Natal-EN.png' width='100%'/>
                        </a>
                        
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/11/2021</label>
                                <h3>SCORING TOP 5%</h3>
                        </div>        
                        <p>Pour la deuxième année consécutive, l'entreprise a été reconnue dans le <strong>TOP 5% des MEILLEURES PME AU PORTUGAL</strong> en termes de Performance et de Solidité Financière !</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão'
                            }}
                        />
                </div>
            </div>
            <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>24/04/2021</label>
                                    <h3>PME Excellence</h3>
                            </div>        
                            <p>Plastifa renouvelle par la 7ème année consécutive son Status PME Excellence.</p>
                        
                            <ImageZoom
                                image={{
                                    src: 'images/PMEex3.png',
                                    alt: 'PME Excelência',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/PMEex3.png',
                                    alt: 'PME Excelência'
                                }}
                            />
                    </div>
                </div>
            </div>
            <div className="row3">
            <div className="Dividir_col2">
                <div data-aos="fade-left"  class="container5">
                        
                        <div class="row2">
                                <label>24/12/2020</label>
                            <h3>SCORING TOP 5%</h3>
                        </div>       
                        <p>L'entreprise est reconnue comme part du TOP 5% MEILLEURES PME AU PORTUGAL au niveau de Perfomance et Solidité Fincancière!</p>
                    
                        <ImageZoom
                            image={{
                                src: 'images/TOP52020.jpg',
                                alt: 'TOP 5%',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP52020.jpg',
                                alt: 'TOP 5%'
                            }}
                        />
                </div>
                </div>
            
            <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>16/04/2020</label>
                                    <h3>EPI’s Plastifa </h3>
                            </div>        
                            <p>De manière à faire sa part dans la lutte contre la pandémie COVID-19, Plastifa s'est dediée à la fabrication de Visières et Fixeurs pour masques.</p>
                        
                            <ImageZoom
                                image={{
                                    src: 'images/Viseiras.png',
                                    alt: 'EPIs PLASTIFA',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/Viseiras.png',
                                    alt: 'EPIs PLASTIFA'
                                }}
                            />
                    </div>
                </div>
                <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>02/10/2020</label>
                                    <h3>Expansion de l'Unité 2</h3>
                            </div>        
                            <p>Plastifa avance pour la construcion de plus un batîment de 1.000m² dans l'unité 2.</p>
                
                            <ImageZoom
                                image={{
                                    src: 'images/NewInst.jpg',
                                    alt: 'Expansão',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/NewInst.jpg',
                                    alt: 'Expansão'
                                }}
                            />

                            <ImageZoom
                                image={{
                                    src: 'images/ExpUn2.jpeg',
                                    alt: 'Golden Gate Bridge',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/ExpUn2.jpeg',
                                    alt: 'Golden Gate Bridge'
                                }}
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Noticias;