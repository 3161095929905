import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './Hero.css';


function Hero() {
    return (
        // <div className='container'>
        //     <div className='row'>
        //         <div className='col-1'>
        //             <h1>oejef </h1>
        //         </div>
        //         <div className='col-1'>
        //             <h1>oejefejnfjfe </h1>
        //         </div>
        //     </div>
        // </div>
            // <div className='heroTop-container'>
            //     <div className='heroTop-container right'>
            //         <div className='right'>
            //             <h1>oejefejnfjwjb </h1>
            //         </div>
            //     </div>
            //     <div className='heroTop-container left'>
            //         <div className='left'>
            //             <h1>oejefejnfjwjb </h1>
            //         </div>
            //     </div>
            // </div>
            <div class="row">
                
                    <div className="containerVid">
                        <div className="marca-dagua"> <h1>Nos produits</h1>
                        {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
                        <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
                        <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
                       </div>
                       <video src="/videos/HighLights 19-04-2021.m4v" autoPlay loop muted />
                        
                    </div>
             </div>
        // <ul>
        //     <h1>oejefejnf </h1>
        //     <h1>oejefejnfjfe </h1>
        // </ul>
        // <ul>
        //     <h1>oejefejnf </h1>
        //     <h1>oejefejnfjfe </h1>
        // </ul>
        // <div className='heroTop-container'>
        //     <ul>
        //     <div className='heroTop-container'>
        //         <video src="/videos/Exterior.mp4" autoPlay loop muted />
        //         <h1>Plásticos Técnicos, Lda.</h1>
        //         <p>Fabrico de Peças Técnicas em Plástico Injetado</p>
        //         {/* <div className="hero-btns">
        //             <Button className='btns' buttonStyle='btn--outline'
        //             buttonSize='btn--large'>GET STARTED</Button>
        //         </div> */}
        //         {/* <div className="hero-btns">
        //             <Button className='btns' buttonStyle='btn--primary'
        //             buttonSize='btn--large'>WATCH TRAILER  <i className='far fa-play-circle'/> </Button>
        //         </div> */}
        //     </div>
        //     </ul>
        //     <ul>
        //     <div className='heroTop-container'>
        //         <video src="/videos/Exterior.mp4" autoPlay loop muted />
        //         <h1>Plásticos Técnicos, Lda.</h1>
        //         <p>Fabrico de Peças Técnicas em Plástico Injetado</p>
        //         {/* <div className="hero-btns">
        //             <Button className='btns' buttonStyle='btn--outline'
        //             buttonSize='btn--large'>GET STARTED</Button>
        //         </div> */}
        //         {/* <div className="hero-btns">
        //             <Button className='btns' buttonStyle='btn--primary'
        //             buttonSize='btn--large'>WATCH TRAILER  <i className='far fa-play-circle'/> </Button>
        //         </div> */}
        //     </div>
        //     </ul>
        // </div>
    )
}

export default Hero