
import React from 'react'

import Footer from '../../Footer/Footer';
import FileUpload from './Upload';
import '../../App.css';


function UploadPage () {
    return (
        <div>
            <FileUpload />
           <Footer />
        </div>
    );
}

export default UploadPage;