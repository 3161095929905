import React from 'react'


class HighCard extends React.Component{
    render(){
        const {data} = this.props;
        return(
            <>
                {
                      data.map((item,index) =>(
                        <div className="card" key={index} >  
                        <div className="Dividir_col left">
                          
                            <img src={item.src} alt=""/>
                         
                        </div>
                       
                          <div ref={this.myref} className="Dividir_col right">

                              <h2>{item.title}</h2>
                              <textarea disabled>
                                {item.description}
                              </textarea >
                              {/* <a href='../components/PDF/docs/Baixa.pdf' target='_blank' rel='noopener noreferrer'>Read More</a> */}
                              </div>
                          </div>
                        
                      ))
                    //  data.map((item,index) =>(
                    //     <div className="card" key={index}>  
                    //       <img src={item.src} alt=""/>
                    //       <div>
                    //           <h2>{item.title}</h2>
                    //           <p>
                    //             {item.description}
                    //           </p>
                    //           <a href="/">Read More</a>
                    //       </div>
                    //     </div>
                    //   ))
                }
            </>
        )
    }
}

export default HighCard;