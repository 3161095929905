import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './TimeLine.css';
import timelineElements from "../Chrono/timelineElements";



import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";


const TimeLine = () => {
    useEffect(()    => {
        Aos.init({ duration: 1500 });
    }, []);
    var styles2 = {
       
       width: '200px',
        right: '-150px'
      };
      var styles1 = {
       
        width: '100px',
        left: '-80px'
        
       };
    return (  
        <VerticalTimeline style={{height:'80%'}}>
             <h1>What's our story?</h1>
        <div className='bodyTime'>
                <div className='timeline'>
                    <ul>
                        <li data-aos="fade-up">
                            <div  className='content'>
                                <h3>Start-up of Plastifa</h3>
                                <p>Plastifa Started its activity with 2 injection molding machines, producing mostly parts for toys and electrical devices.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>1997</h4>
                                
                            </div>
                            <img className='timeline ul li' src='images/LogoAntigo.png' />
                           
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO 9001:2000 Certificate</h3>
                                <p>With this first Quality certification, Plastifa managed to penetrate new business sectors, including the Automotive Industry.</p>
                                
                            </div>

                            <div className='time'>
                                <h4>2003</h4>

                            </div>
                            <img className='timeline ul li' src='images/ISO9001.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Constrution of Plant 1</h3>
                                <p>The construction of a new production unit allowed Plastifa to increase in number of machines and personel.</p>
                            </div>
                            <div className='time'>
                                <h4>2007</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un1.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3 >ISO/TS & PME Líder</h3>
                                <p>This year Plastifa achieve the ISO/TS Certificate, specific for Automotive Industry and the distinction "PME Líder", transmiting more trust to its Business Partners. </p>
                                
                            </div>
                            <div className='time'>
                                <h4>2011</h4>
                            </div>
                            <img className='timeline ul li' style={styles2} src='images/ISOLIDER.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>PME Execelência</h3>
                                <p>For the last 8 years in a row, the company managed to keep the distinction "PME Excelência".</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2014</h4>
                            </div>
                            <img className='timeline ul li' style={styles1} src='images/PMEex2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construction of the Social Building</h3>
                               <p>New facilities were created to give more confort and better working conditions to its employees.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2015</h4>
                            </div>
                            <img className='timeline ul li' style={{width:'150px', right:'-100px'}} src='images/EdSocial.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construction of Plant II</h3>
                                <p>With no space to grow on Plant 1, Plastifa proceed to the construction of new facilities close by.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2018</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Scoring TOP 5%</h3>
                                <p>Evaluating the Financial Performance and Consistency Plastifa was awarded with the distinction " TOP 5% Melhores PME de Portugal".</p>
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/TOP5SCORING.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Expansion of Plant II</h3>
                                <p>Observing its business grow, the company took another step to its sustainable development. </p>
                             
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2Total.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>A greener future</h3>
                                <p>With a focus on sustainability and a greener future, Plastifa takes the step of investing in solar energy production.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2023</h4>
                            </div>
                            <img className='timeline ul li' src='images/PaineisSolares.JPG' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO 14001</h3>
                                <p>With focus on the Environment and a more sustainable Future, Plastifa is now certified ISO 14001 - Environmental Management Systems.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2024</h4>
                            </div>
                            <img className='timeline ul li' src='images/14001.jpeg' />
                        </li>                        
                    </ul>
                </div>
       </div>
       </VerticalTimeline>
    )
}

export default TimeLine