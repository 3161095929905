
import React from 'react';





function Empresa() {

    return (
        <div class="container7">
                 <div class="row2">
                              <h3>Qui sommes-nous?</h3>
                 </div>        
                 <p>Plastifa est une entreprise avec plus de 20 années d'expérience dans la production de pièces plastiques injectées.</p>
                 <p>Concentrée sur la qualité de ses produits et services, a une equipe d'environ 90 personnes préparées à répondre aux nécéssités plus exigentes du marché, étant 85% de sa production dédiée au secteur automobile et le restant divisé en differents secteurs, entre eux : Médicaux, Optiques et Sanitaires.</p>
                 <p>Son parc de production, compte à ce jour 33 machines d'injection, préparées pour processer tout type de matériaux thermoplastiques. Au delá de l'injection conventionnelle, se demarquent les téchniques de sur-injection, bi-injection, injection verticale et la forte expérience en pièces extrêmement téchniques et d'aspect visuel.</p>
                 <p>Plastifa est fière de rénover son status PME Excellence par la huitième année consécutive.</p>
        </div>      
    )
}


export default Empresa;