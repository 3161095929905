import React from 'react';
import '../../App.css';
import Hero from '../HeroEmpresaFr'
import Empresa from '../Empresa/EmpresaFr'
import Missao from '../Empresa/MissaoFr'
import PolitGest from '../Empresa/PolitGestFr'
import SalesDist from '../Empresa/SalesDist'
import Footer from '../../Footer/FooterFr';

function Noticias(){
    return (
        <div>
            <Hero/>
            <Empresa/>
            <SalesDist/>
            <Missao/>
            <PolitGest/>
            <Footer/>
            
        </div>
    );
}

export default Noticias;
