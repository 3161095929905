import React, { useEffect } from "react"; 
import Aos from "aos";
import "aos/dist/aos.css"
import "./Boxes.css"

const CorpServ = () => {
 useEffect(() => {
     Aos.init({duration: 2000});
 }, []);

    return (
        <div className="App">
            
            <div className="App">
                <div className="grids">
                 <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Injeção Plástica</h2>
                            <p>O serviço de Injeção Plástica com as mais altas garantias de Qualidade.</p>
                            <p>Com mais de 20 anos de experiência no processamento de todo o tipo de materiais termoplásticos.</p>
                            <p>Contamos em 2021 com um parque de 33 máquinas de injeção com capacidades entre as 20 e as 350 ton de força de fecho.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img  style={{marginTop:'75px'}} src='images/InjPlas.png'></img>
                        </div>
                    </div>

                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/DesenProd.png'></img>
                        </div>
                        <div className="Dividir_col right">
                            <h2>Desenvolvimento de produto</h2>
                            <p>Uma equipa capaz de dar apoio no desenvolvimento de produto, sugestão de melhorias, redução de custos e análises de factibilidades.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                            <h2>Projetos Chave-na-mão</h2>
                            <p>Podemos trabalhar sobre um conceito “Chave-na-mão” sendo responsáveis por todo o projeto e construção das ferramentas necessárias para a produção do produto final, ou trabalhar com ferramentas transferidas já validadas pelos nossos clientes.</p>
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/TurnkeyProj.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/PreMont2.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Pré-montagens</h2>
                            <p>Capazes de realizar pré-montagens de outras outros componentes como peças plásticas,  insertos, espumas, películas , entre outros.</p>
                            <p>Realizamos esta operação preferencialmente em linha com a máquina de injeção.</p>                        
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes">
                        <div className="Dividir_col left">
                        <h2>Impressão 3D por SLA & FDM</h2>
                            <p>Com a Tecnologia de Impressão 3D por SLA e FDM  conseguimos obter mais informação na fase de desenvolvimento do produto, assim como encontrar soluções mais adequadas para a industrialização da sua vida série.</p>                     
                        </div>
                        <div className="Dividir_col right">
                            <img src='images/3DPrinter.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/GOM.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Digitalização 3D Metrology – 3D Scanning</h2>
                            <p>A Digitalização 3D realizada internamente equipa-nos com a informação  e ferramentas necessárias para providenciar aos nossos clientes relatórios dimensionais robustos, além de permitir mais confiança na resolução de problemas.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="boxes"> 
                        <div className="Dividir_col left">
                            <h2>Polímeros de Elevado Desempenho</h2>
                            <p>Equipados, preparados e experienciados para a injeção de todo o tipo de termoplásticos, incluindo materiais de elevado desempenho e com condições de processamento extremas.</p>
                        </div>
                        <div className="Dividir_col right">
                        <img src='images/HighPoly.png'></img>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="boxes">
                        <div className="Dividir_col left">
                            <img src='images/ColorMat.png'></img>
                        </div>
                        <div className="Dividir_col right">
                        <h2>Placas de teste de materiais e cores</h2>
                            <p>Oferecemos aos nossos clientes a possibilidade de testar vários tipos de materiais e cores com as nossas “placas de teste”.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CorpServ;