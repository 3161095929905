import "../../App.css";
import Mailer from "./mailer";
import React from 'react'
import Contact from '../Contacts/contactFr'
import HeroContact from '../HeroContact';

function Mailersend() {
    return(
        <div>
            <Mailer />
        </div>
    );
}

export default Mailersend;