import React from 'react'
import Footer from '../../Footer/Footer';
import Hero from './HeroEmprego';
import Conteudo from './EmpregoText'
import Esponta from './Esponta'
import '../../App.css';


function Emprego () {
    return (
        <div>
            <Hero/>
            <Esponta/>
           <Footer />
        </div>
    );
}

export default Emprego;
