
// import React from 'react';
import React, { useEffect } from "react"; 
import Aos from "aos";
import ImageZoom from 'react-medium-image-zoom'
import "aos/dist/aos.css"
// import '../Noticias/Noticias.css';
// import 'bootstrap/dist/css/bootstrap.css';



const  Noticias = () => {
    useEffect(() => {
        Aos.init({duration: 3000});
    }, []);



    return (
        // <div className="grids">
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        //     <div className="Dividir_col">
        //     <div class="container5">
        //             <div class="row2">
        //                         <h3>SCORING TOP 5%</h3>
        //             </div>        
        //             <img src='images/TOP52020.jpg'></img>
        //     </div>
        //     </div>
        // </div>
        <div>
            <div className="row3">
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/05/2024</label>
                                <h3>ISO 14001</h3>
                        </div>        
                        <p>Plastifa konzentriert sich auf die Umwelt und eine nachhaltigere Zukunft und ist jetzt nach ISO 14001 – Umweltmanagementsysteme – zertifiziert.</p>
                        
                        <a href="https://www.linkedin.com/feed/update/urn:li:activity:7196080381643616257" width='100%'>
                            <img  src='images/14001.jpeg' width='100%'/>
                        </a>
                        
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2023</label>
                                <h3>Eine grünere Zukunft</h3>
                        </div>        
                        <p>Plastifa setzt auf Nachhaltigkeit und eine grünere Zukunft und investiert in die Erzeugung von Solarenergie.</p>
                       
                       
                        
                        <a href="https://www.linkedin.com/posts/plastifa_com-foco-na-sustentabilidade-e-num-futuro-activity-7157008055249821696-QJP5?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/PaineisSolares.JPG' width='100%'/>
                        </a>
                        
                </div>
            </div>
        <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>25/10/2023</label>
                                <h3>UNTER DEN TOP 5%</h3>
                        </div>        
                        <p>Zum vierten Mal in Folge wurde das Unternehmen als eines der TOP 5% der leistungsstärksten KMU in Portugal in Bezug auf Leistung und Finanzkraft ausgezeichnet!</p>

                        <ImageZoom
                            image={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '20em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP52023.png',
                                alt: 'Expansão'
                            }}
                        />
                        
                </div>
            </div>
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>01/06/2023</label>
                                <h3>Neues Video</h3>
                        </div>        
                        <p>In einer Welt, die von ständigem Fortschritt und Innovation geprägt ist, ist Plastifa stolz darauf, sich als Unternehmen mit Spitzentechnologie und innovativen Ideen zu präsentieren.</p>
                       <br/>
                       <br/>
                        <a href="https://www.linkedin.com/posts/plastifa_plastifa-your-partner-for-plastic-parts-activity-7059587841172865024-LQ9A?utm_source=share&utm_medium=member_desktop" width='100%'>
                            <img  src='images/NotVideo.png' width='100%'/>
                        </a>
                </div>
            </div>
            </div>

            <div className="row3">
            <div className="Dividir_col2">
            <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>17/10/2022</label>
                                <h3>UNTER DEN TOP 5%</h3>
                        </div>        
                        <p>Zum dritten Mal in Folge wurde das Unternehmen in Bezug auf Leistung und Finanzkraft als eines der Top 5 % der leistungsstärksten KMU in Portugal ausgezeichnet!</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOPSCORING22.png',
                                alt: 'Expansão'
                            }}
                        />
                </div>
            </div>   
            <div className="Dividir_col2">
                <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>23/12/2021</label>
                                <h3>Christmas Postcard </h3>
                        </div>        
                        <p>Please click on the image bellow to access Plastifa Postcard:</p>
                       
                       
                        
                        <a href="https://plastifa.pt/Christmas" width='100%'>
                            <img  src='images/Natal-EN.png' width='100%'/>
                        </a>
                        
                </div>
            </div>
            <div className="Dividir_col2">
            <div data-aos="fade-left" class="container5">
                        <div class="row2">
                        <label>15/11/2021</label>
                                <h3>UNTER DEN TOP 5 %</h3>
                        </div>        
                        <p>Zum zweiten Mal in Folge wurde das Unternehmen in Bezug auf Leistung und Finanzkraft als eines der Top 5 % der leistungsstärksten KMU in Portugal ausgezeichnet!</p>
            
                        <ImageZoom
                            image={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP2021.jpg',
                                alt: 'Expansão'
                            }}
                        />
                </div>
            </div>
            <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>24/04/2021</label>
                                    <h3>PME Excelência</h3>
                            </div>        
                            <p>Plastifa erhält das siebte Jahr in Folge den Status „PME Excelência“</p>
                        
                            <ImageZoom
                                image={{
                                    src: 'images/PMEex3.png',
                                    alt: 'PME Excelência',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/PMEex3.png',
                                    alt: 'PME Excelência'
                                }}
                            />
                    </div>
                </div>

            </div>

            <div className="row3">
            <div className="Dividir_col2">
                <div data-aos="fade-left"  class="container5">
                        
                        <div class="row2">
                                <label>24/12/2020</label>
                            <h3>UNTER DEN TOP 5 %</h3>
                        </div>       
                        <p>Das Unternehmen zählt zu den Top 5 % der leistungsstärksten KMU in Portugal, gemessen an der wirtschaftlichen Leistungsfähigkeit und Finanzstärke.</p>
                    
                        <ImageZoom
                            image={{
                                src: 'images/TOP52020.jpg',
                                alt: 'TOP 5%',
                                className: 'img',
                                style: { width: '50em' }
                            }}
                            zoomImage={{
                                src: 'images/TOP52020.jpg',
                                alt: 'TOP 5%'
                            }}
                        />
                </div>
                </div>
            <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>16/04/2020</label>
                                    <h3>PSA von Plastifa</h3>
                            </div>        
                            <p>Im Rahmen des Engagements im Kampf gegen die COVID-19-Pandemie fertigt Plastifa Gesichtsvisiere und Maskenhalterungen.</p>
                        
                            <ImageZoom
                                image={{
                                    src: 'images/Viseiras.png',
                                    alt: 'EPIs PLASTIFA',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/Viseiras.png',
                                    alt: 'EPIs PLASTIFA'
                                }}
                            />
                    </div>
                </div>
                <div className="Dividir_col2">
                    <div data-aos="fade-left" class="container5">
                            <div class="row2">
                            <label>02/10/2020</label>
                                    <h3>Erweiterung von Werk 2</h3>
                            </div>        
                            <p>Plastifa erweitert das Werk 2 mit der Errichtung eines weiteren Gebäudeteils mit 1.000 m² Fläche.</p>
                
                            <ImageZoom
                                image={{
                                    src: 'images/NewInst.jpg',
                                    alt: 'Expansão',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/NewInst.jpg',
                                    alt: 'Expansão'
                                }}
                            />

                            <ImageZoom
                                image={{
                                    src: 'images/ExpUn2.jpeg',
                                    alt: 'Golden Gate Bridge',
                                    className: 'img',
                                    style: { width: '50em' }
                                }}
                                zoomImage={{
                                    src: 'images/ExpUn2.jpeg',
                                    alt: 'Golden Gate Bridge'
                                }}
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Noticias;