import React from 'react';
import './Hero.css';


function Hero() {
    return (
            <div class="page-heading">
        
            <div class="heading-content">
                <h1 style={{marginBottom:'75px'}}>Your Partner for Plastic Parts</h1>
               
            </div>
       
    </div>
    )
}

export default Hero