import React from 'react';
import '../../src/App.css'
import {Button} from './Navbar/Button';
import './HeroSection.css';

function HeroSection() {
    return (
        // <div className='hero-container'>
        //     <video src="/videos/Exterior.mp4" autoPlay loop muted />
        //     <h1>Plásticos Técnicos, Lda.</h1>
        //     <p>Fabrico de Peças Técnicas em Plástico Injetado</p>
        //     {/* <div className="hero-btns">
        //         <Button className='btns' buttonStyle='btn--outline'
        //         buttonSize='btn--large'>GET STARTED</Button>
        //     </div> */}
        //     {/* <div className="hero-btns">
        //         <Button className='btns' buttonStyle='btn--primary'
        //         buttonSize='btn--large'>WATCH TRAILER  <i className='far fa-play-circle'/> </Button>
        //     </div> */}
        // </div>
<div class="containerHero">
        <div class="row"> 
        <div className="containerVid2">
            <div className="marca-dagua4"> 
            <h1>Plásticos Técnicos, Lda.</h1>
             <p>Ihr Partner für Kunststoffteile</p>
            {/* <p>A Plastifa é uma empresa dedicada à injeção de materiais plásticos com a particularidade de, ao longo dos anos, se ter especializado no fabrico de peças extremamente técnicas e de aspeto.</p>
            <p>A Plastifa injeta todo o tipo de termoplásticos, tendo igualmente uma forte experiência com materiais translúcidos e flexíveis.</p>
            <p>Para além do método de injeção convencional, a esta empresa é capaz de utilizar técnicas como: sobre-injeção, injeção vertical e bi-injeção.</p> */}
           </div>
             <video autoPlay loop muted playsinline src="/videos/Exterior.m4v"  />
        </div>
 </div>
 </div>
    )
}

export default HeroSection
