import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';


function NavbarEn() {
    const [click, setclick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setclick(!click);
    const closeMobileMenu = () => setclick(false);

    const showButton = () => {
        if(window.innerWidth <= 0){
            setButton(false);
        } else{
            setButton(true);
        }
    };

    useEffect(() =>{showButton();}, [] );

    window.addEventListener('resize', showButton);

    const changeBackground = () => {
        if(window.scrollY >= 0){
            setNavbar(true)
        }
        else{
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    
    return (
        <>
           <nav className='navbar active'>
               <div className="navbar-container">
               <a href="/HomeEn">
               <img
               
        alt=""
        src="/images/LogoPlas.png"
        width="120px"
       
        className="d-inline-block align-top"
      /></a>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/EmpresaEn' className='nav-links active' onClick={closeMobileMenu}>
                            Company
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/servicesEn' className='nav-links active' onClick={closeMobileMenu}>
                            Services
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/productsen' className='nav-links active' onClick={closeMobileMenu}>
                            Products
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/NoticiasEn' className='nav-links active' onClick={closeMobileMenu}>
                            News
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <Link to='/ContactPageen' className='nav-links active' onClick={closeMobileMenu}>
                             Contacts
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                    
                    <div class="dropdown ">
                            {/* <button class="dropbtn"> <i class='fas fa-globe'  />  */}
                            <button class="dropbtn"><img style={{maxBlockSize:'25%'}} src='images/lingua-inglesa.png'></img>
                            <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="dropdown-content">
                            <a href="/"> <img src='images/portugal.png'></img> Português</a>
                            <a href="/HomeEn"><img src='images/lingua-inglesa.png'></img> English</a>
                            <a href="/HomeFr"><img src='images/france.png'></img> Français</a>
                            <a href="/HomeDE"><img src='images/alemao.png'></img> Deutsch</a>
                            </div>
                        </div> 
                    
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/products' className='nav-links active' onClick={closeMobileMenu}>
                            Emprego
                        </Link>
                    </li> */}
                </ul>
                {/* {button && <Button buttonStyle='btn--outline'>Sign Up</Button>} */}
               </div>
           </nav>
        </>
    )

}

export default NavbarEn
