import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './TimeLine.css';
import timelineElements from "../Chrono/timelineElements";



import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";


const TimeLine = () => {
    useEffect(()    => {
        Aos.init({ duration: 1500 });
    }, []);
    var styles2 = {
       
       width: '200px',
        right: '-150px'
      };
      var styles1 = {
       
        width: '100px',
        left: '-80px'
        
       };
    return (  
        <VerticalTimeline style={{height:'80%'}}>
             <h1>Um pouco da nossa história</h1>
        <div className='bodyTime'>
                <div className='timeline'>
                    <ul>
                        <li data-aos="fade-up">
                            <div  className='content'>
                                <h3>Arranque da Plastifa</h3>
                                <p>A Plastifa iniciou a sua atividade com 2 máquinas de injeção, produzindo maioritariamente peças para brinquedos e componentes elétricos.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>1997</h4>
                                
                            </div>
                            <img className='timeline ul li' src='images/LogoAntigo.png' />
                           
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Certificação ISO 9001:2000</h3>
                                <p>Com esta primeira certificação de Qualidade a Plastifa conseguiu alcançar mais áreas de negócio, incluindo o Sector Automóvel.</p>
                                
                            </div>

                            <div className='time'>
                                <h4>2003</h4>

                            </div>
                            <img className='timeline ul li' src='images/ISO9001.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construção da Unidade I</h3>
                                <p>A Construção de uma Nova Unidade produtiva que permitiu que a empresa ampliasse em número de máquinas e colaboradores.</p>
                            </div>
                            <div className='time'>
                                <h4>2007</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un1.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3 >Certificação ISO/TS e Estatuto PME Líder</h3>
                                <p>Este ano marcou a Plastifa pela certificação ISO/TS, dedicada ao Setor Automóvel e pelo alcançar do Estatuto PME Líder, transmitindo mais confiança aos seus parceiros de negócio.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>2011</h4>
                            </div>
                            <img className='timeline ul li' style={styles2} src='images/ISOLIDER.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Estatuto PME Excelência</h3>
                                <p>Perfazem agora 8 anos consecutivos empresa tem vindo a manter o Estatuto PME Excelência.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2014</h4>
                            </div>
                            <img className='timeline ul li' style={styles1} src='images/PMEex2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construção do Edifício Social</h3>
                               <p>Novo edifício de 3 pisos, construído com o intuito de criar mais condições para os seus colaboradores.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2015</h4>
                            </div>
                            <img className='timeline ul li' style={{width:'150px', right:'-100px'}} src='images/EdSocial.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construção da Unidade II</h3>
                                <p>Encontrando-se sem espaço para expansão na Unidade I a Plastifa avançou para a construção de novas instalações na região.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2018</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Scoring TOP 5%</h3>
                                <p>Tendo como base de avaliação o Desempenho e Solidez Financeira, a Plastifa alcançou a distinção: TOP 5% Melhores PME de Portugal.</p>
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/TOP5SCORING.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Expansão da Unidade II</h3>
                                <p>Vendo o seu negócio a crescer a empresa deu mais um passo no sentido do seu desenvolvimento sustentável. </p>
                             
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2Total.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Um futuro mais verde</h3>
                                <p>Com foco na Sustentabilidade e num Futuro mais verde, a Plastifa dá o passo de investir na produção de Energia Solar. </p>
                             
                            </div>
                            <div className='time'>
                                <h4>2023</h4>
                            </div>
                            <img className='timeline ul li' src='images/PaineisSolares.JPG' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO 14001</h3>
                                <p>Com foco no Ambiente e num Futuro mais sustentável a Plastifa certificou-se ISO 14001 - Sistema de Gestão Ambiental.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2024</h4>
                            </div>
                            <img className='timeline ul li' src='images/14001.jpeg' />
                        </li>
                    </ul>
                </div>
       </div>
       </VerticalTimeline>
    )
}

export default TimeLine