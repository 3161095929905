
import React from 'react';





function Empresa() {

    return (
        <div class="container7">
                 <div class="row2">
                              <h3>A Empresa Plastifa</h3>
                 </div>        
                 <p>A Plastifa é uma empresa  com mais de 20 anos de experiência na produção de peças plásticas por injeção. </p>
                 <p>Com foco na qualidade dos seus produtos e serviços, conta com uma equipa de cerca de 90 pessoas preparada para responder às necessidades mais exigentes do mercado, estando cerca de 85% da sua produção dedicada ao setor automóvel e os restantes 15% divididos por diversas áreas, entre elas a Médica, Ótica e a Sanitária.</p>
                 <p>No seu parque, conta ao dia de hoje com 33 máquinas de injeção, preparadas para o processamento de todo o tipo de materiais termoplásticos. Com estas, para além da injeção convencional, destacam-se as técnicas de sobre-injeção, bi-injeção, injeção vertical e a forte experiência em peças extremamente técnicas e de aspeto visual.</p>
                 <p>A Plastifa tem o orgulho de renovar em 2022 o estatuto PME Excelência pelo 8º ano consecutivo.</p>
        </div>      
    )
}


export default Empresa;