import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSectionDe'
import Cards from '../CardsDe';
import CardsCert from '../Certificados/CardsCertDe';
import Footer from '../../Footer/FooterDe';
import TimeLine from '../Timeline/TimeLineDe';
import Chrono from '../Chrono/chrono';


function Home () {
    return (
        <div>
            <HeroSection/>
            <Cards/>
            <CardsCert />
            <TimeLine />
            <Footer />
        </div>
    );
}

export default Home;