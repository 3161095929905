import React from 'react'
import Cargo from './Cargo'
import Ficha from './Ficha'
import Footer from '../../Footer/Footer';
import Form from './Form';
import './Recrut.css';


function ContactPage () {
    return (
        <div>
            {/* <Ficha/>
           <Cargo/> */}
           <Form/>
           {/* <Footer /> */}
        </div>
    );
}

export default ContactPage;