import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './TimeLine.css';
import timelineElements from "../Chrono/timelineElements";



import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";


const TimeLine = () => {
    useEffect(()    => {
        Aos.init({ duration: 1500 });
    }, []);
    var styles2 = {
       
       width: '200px',
        right: '-150px'
      };
      var styles1 = {
       
        width: '100px',
        left: '-80px'
        
       };
    return (  
        <VerticalTimeline style={{height:'80%'}}>
             <h1>Notre Histoire</h1>
        <div className='bodyTime'>
                <div className='timeline'>
                    <ul>
                        <li data-aos="fade-up">
                            <div  className='content'>
                                <h3>Début de Plastifa</h3>
                                <p>Plastifa a debuté son activité avec 2 machines à injection, produisant majoritairement pièces pour jouets et composants eléctriques.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>1997</h4>
                                
                            </div>
                            <img className='timeline ul li' src='images/LogoAntigo.png' />
                           
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Certification ISO 9001:2000</h3>
                                <p>Avec cette première certification, Plastifa à réussi à atteindre de nouveaux secteurs d'affaires, le Secteur Automobile inclus.</p>
                                
                            </div>

                            <div className='time'>
                                <h4>2003</h4>

                            </div>
                            <img className='timeline ul li' src='images/ISO9001.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construction de l'Unité I</h3>
                                <p>La construction d'une nouvelle unité de production à permis à Plastifa d'augmenter le numéro de machines et personnel.</p>
                            </div>
                            <div className='time'>
                                <h4>2007</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un1.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3 >Certification ISO/TS et Status PME Líder</h3>
                                <p>Cette année a marqué Plastifa par la certification ISO/TS, dediée au Secteur Automobile et l'atteinte du Status "PME Líder", transmettant plus de confiance à ses partenaires d'affaires.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>2011</h4>
                            </div>
                            <img className='timeline ul li' style={styles2} src='images/ISOLIDER.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Status PME Excellence</h3>
                                <p>Après 8 années consécutives, l'entreprise maintien à tenir son Status PME Excelência.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2014</h4>
                            </div>
                            <img className='timeline ul li' style={styles1} src='images/PMEex2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construction du Batîment Social</h3>
                               <p>Un nouveau bâtiment à été construit dans l'intérêt d'augmenter les conditions pour ses employés.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2015</h4>
                            </div>
                            <img className='timeline ul li' style={{width:'150px', right:'-100px'}} src='images/EdSocial.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Construction de l'Unité II</h3>
                                <p>Sans emplacement pour expansion de l'unité 1, Plastifa a avancé pour la construction de nouvelles installations dans la région.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2018</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Scoring TOP 5%</h3>
                                <p>Sur la base de l'évaluation de la performance et de la solidité financière, Plastifa a obtenu la distinction : TOP 5% des meilleures PME au Portugal.</p>
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/TOP5SCORING.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Expansion de l'Unité II</h3>
                                <p>Voyant son activité grandir, l'entreprise a franchie une nouvelle étape vers son développement durable.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2Total.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Un avenir plus vert</h3>
                                <p>Dans un souci de durabilité et d'un avenir plus vert, Plastifa investit dans la production d'énergie solaire.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2023</h4>
                            </div>
                            <img className='timeline ul li' src='images/PaineisSolares.JPG' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO 14001</h3>
                                <p>En mettant l’accent sur l’environnement et un avenir plus durable, Plastifa a été certifié ISO 14001 - Système de gestion environnementale.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2024</h4>
                            </div>
                            <img className='timeline ul li' src='images/14001.jpeg' />
                        </li>                                               
                    </ul>
                </div>
       </div>
       </VerticalTimeline>
    )
}

export default TimeLine