
import React from 'react';





function PolitGest() {

    return (
        <div class="container7" style={{marginBottom:'70px', marginTop:'50px'}}>
                 <div class="row2">
                              <h3>Management Policy</h3>
                 </div>        
                 <p><strong>1. </strong> Exceed the needs and expectations of our customers, with regard to product/service quality levels and delivery times;</p>
                 <p><strong>2. </strong> The permanent maintenance of a high level of quality in our products. As support, we resort to Self-control, group work and the philosophy of Continuous Improvement and Productivity, always prioritizing Prevention instead Detection;</p>
                 <p><strong>3. </strong> Adopting behaviors that Prevent Pollution, by controlling and minimizing the most significant environmental impacts, such as energy consumption, resource consumption and waste production;</p>
                 <p><strong>4. </strong> To ensure the identification and the compliance with all legal requirements within the scope of its environmental aspects, as well as other requirements subscribed by the organization;</p>
                 <p><strong>5. </strong> Continuous improvement of the effectiveness of the Management System;</p>
                 <p><strong>6. </strong> The fulfillment, by our products and services, of the known and potential customers requirements, at the same level or better than our competitors;</p>
                 <p><strong>7. </strong> High technical competence, flexibility and innovation capacity to satisfy customer requirements;</p>
                 <p><strong>8. </strong> The ability to maintain a good level of involvement and training of our employees, so that we can have the reflection of their contribution to improving the Quality and Productivity of products;</p>
                 <p><strong>9. </strong> The permanent maintenance of a relationship of cooperation and continuous improvement with our suppliers and customers, which may be reflected in the quality of our products and in the continuous improvement of the organization's environmental performance;</p>
                 <p><strong>10. </strong> The design and maintenance of safe workplaces, in order to avoid work accidents.</p>
                 <br></br>
                 <p style={{textAlign:'right'}}><strong>16/10/2023 </strong> </p>   
        </div>      
    )
}


export default PolitGest;