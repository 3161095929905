import React from 'react';
import Highlights from '../Services/HighlightsFr'
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSectionServices'
import Hero from '../HeroContFr'
import HeroCorp from '../HeroCorp'
import Footer from '../../Footer/FooterFr';

function Products(){
    return (
        <div>
            <Hero/>
            <Highlights />
            <Footer/>
{/*            
            <HeroCorp/> */}
            
        </div>
    );
}

export default Products;
