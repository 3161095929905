
import React from 'react';





function PolitGest() {

    return (
        <div class="container7" style={{marginBottom:'70px', marginTop:'50px'}}>
                 <div class="row2">
                              <h3>Política de Gestão</h3>
                 </div>        
                 <p><strong>1. </strong> Superar as necessidades e expectativas dos nossos clientes, no que diz respeito a níveis da Qualidade do produto/serviço e prazos de entrega;</p>
                 <p><strong>2. </strong> A permanente manutenção de um elevado nível de Qualidade dos nossos produtos. Como suporte, recorre-se ao Autocontrolo, ao trabalho em grupo e à filosofia de Melhoria Contínua e da Produtividade, privilegiando sempre a Prevenção à Deteção;</p>
                 <p><strong>3. </strong> Adotar comportamentos que Previnam a Poluição, por intermédio do controlo e minimização dos impactes ambientais mais significativos, tais como o consumo de energia, o consumo de recursos e a produção de resíduos;</p>
                 <p><strong>4. </strong> Assegurar a identificação oportuna e o cumprimento de todos os requisitos legais aplicáveis no âmbito dos seus aspetos ambientais, bem como outros subscritos pela organização;</p>
                 <p><strong>5. </strong> A melhoria contínua da eficácia do Sistema de Gestão;</p>
                 <p><strong>6. </strong> O cumprimento, por parte dos nossos produtos e serviços, das exigências conhecidas e potenciais dos nossos clientes, igualmente ou melhor que os dos nossos concorrentes;</p>
                 <p><strong>7. </strong> Elevada competência técnica, flexibilidade e capacidade de inovação para satisfação dos requisitos dos clientes;</p>
                 <p><strong>8. </strong> A capacidade de manter um bom nível de envolvimento e formação dos nossos colaboradores, para podermos ter o reflexo da sua contribuição na melhoria da Qualidade e da Produtividade dos produtos;</p>
                 <p><strong>9. </strong> A permanente conservação de uma relação de cooperação e de melhoria contínua com os nossos fornecedores e clientes, que possa refletir-se na Qualidade dos nossos produtos e na melhoria contínua do desempenho ambiental da organização;</p>
                 <p><strong>10. </strong> A conceção e conservação de postos de trabalho seguros, de forma a evitar acidentes de trabalho.</p>
                 <br></br>
                 <p style={{textAlign:'right'}}><strong>16/10/2023 </strong> </p>   
        </div>      
    )
}


export default PolitGest;