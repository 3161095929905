import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './TimeLine.css';
import timelineElements from "../Chrono/timelineElements";



import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";


const TimeLine = () => {
    useEffect(()    => {
        Aos.init({ duration: 1500 });
    }, []);
    var styles2 = {
       
       width: '200px',
        right: '-150px'
      };
      var styles1 = {
       
        width: '100px',
        left: '-80px'
        
       };
    return (  
        <VerticalTimeline style={{height:'80%'}}>
             <h1>Unsere Firmengeschichte</h1>
        <div className='bodyTime'>
                <div className='timeline'>
                    <ul>
                        <li data-aos="fade-up">
                            <div  className='content'>
                                <h3>Die Anfänge von Plastifa</h3>
                                <p>Plastifa beginnt seine Geschäftstätigkeit mit 2 Spritzgießmaschinen und produziert vor allem Kunststoffteile für Spielzeuge und elektrische Bauteile.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>1997</h4>
                                
                            </div>
                            <img className='timeline ul li' src='images/LogoAntigo.png' />
                           
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Zertifizierung nach ISO 9001:2000</h3>
                                <p>Mit dieser ersten Qualitätsmanagement-Zertifizierung kann Plastifa neue Branchen für sich erschließen, einschließlich der Automobilbranche.</p>
                            </div>

                            <div className='time'>
                                <h4>2003</h4>
                            </div>
                            <img className='timeline ul li' src='images/ISO9001.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Bau von Werk I</h3>
                                <p>Der Bau eines neuen Werks ermöglicht dem Unternehmen den Maschinenpark und die Belegschaft zu erweitern.</p>
                            </div>
                            <div className='time'>
                                <h4>2007</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un1.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO/TS-Zertifizierung und Status „PME Líder“</h3>
                                <p>Plastifa erhält die ISO/TS-Zertifizierung für die Automobilbranche und den Status „PME Líder“ und kann so zusätzliches Vertrauen bei den Geschäftspartnern aufbauen.</p>
                                
                            </div>
                            <div className='time'>
                                <h4>2011</h4>
                            </div>
                            <img className='timeline ul li' style={styles2} src='images/ISOLIDER.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Status „PME Excelência“</h3>
                                <p>Das achte Jahre in Folge wird das Unternehmen mit dem Status „PME Excelência“ ausgezeichnet.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2014</h4>
                            </div>
                            <img className='timeline ul li' style={styles1} src='images/PMEex2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Bau eines Sozialgebäudes</h3>
                               <p>Bau eines neuen, dreistöckigen Gebäudes mit dem Zweck, mehr Komfort und bessere Arbeitsbedingungen für die Mitarbeiter zu schaffen.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2015</h4>
                            </div>
                            <img className='timeline ul li' style={{width:'150px', right:'-100px'}} src='images/EdSocial.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Bau von Werk II</h3>
                                <p>Da das Werk I keine Wachstumsmöglichkeiten mehr bietet, plant Plastifa den Bau eines weiteren Werks in direkter Umgebung.</p>
                            </div>
                            
                            <div className='time'>
                                <h4>2018</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Unter den Top 5 %</h3>
                                <p>Auf Grundlage der wirtschaftlichen Leistungsfähigkeit und Finanzstärke erhält Plastifa die Auszeichnung „TOP 5% Melhores PME de Portugal“.</p>
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/TOP5SCORING.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Ausbau von Werk II</h3>
                                <p>In Anbetracht des Firmenwachstums unternimmt das Unternehmen einen weiteren Schritt in Richtung nachhaltige Entwicklung.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2021</h4>
                            </div>
                            <img className='timeline ul li' src='images/Un2Total.png' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>Eine grünere Zukunft</h3>
                                <p>Plastifa setzt auf Nachhaltigkeit und eine grünere Zukunft und investiert in die Erzeugung von Solarenergie.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2023</h4>
                            </div>
                            <img className='timeline ul li' src='images/PaineisSolares.JPG' />
                        </li>
                        <li data-aos="fade-up">
                            <div className='content'>
                                <h3>ISO 14001</h3>
                                <p>Plastifa konzentriert sich auf die Umwelt und eine nachhaltigere Zukunft und ist jetzt nach ISO 14001 – Umweltmanagementsysteme – zertifiziert.</p>
                             
                            </div>
                            <div className='time'>
                                <h4>2024</h4>
                            </div>
                            <img className='timeline ul li' src='images/14001.jpeg' />
                        </li>                        
                    </ul>
                </div>
       </div>
       </VerticalTimeline>
    )
}

export default TimeLine