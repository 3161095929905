import React from 'react';
import { Link } from 'react-router-dom';

function CardItemCert(props) {
  return (
    <>
      <li className='cards__item'>

        <Link className='cards__item__link2' to={props.path} target='_blank' rel='noopener noreferrer'>



            <img src={props.src}  />

         <div className='cards__item__info2'>
             <h5 className='cards__item__text2'>{props.text}</h5>
        </div>
         
        </Link>
        
      </li>
    </>
  );
}

export default CardItemCert;
