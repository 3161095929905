import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    
    <div className='cards'>
      <div>
        <h1>Highlights</h1>
      </div>
    
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='images/TechnicalParts.png'
              // text='Fortes da Injeção de peças técnicas, cuja funcionalidade é vital, recorrendo a um controlo de qualidade apertado e a um processo de injeção estável e automatizado.'
              label='Technische Teile'
              path='/productsDE'
            />
             <CardItem
              src='images/2Kinjection2.png'
              // text='Contamos com experiência na bi-injeção – injeção de dois materiais num só processo de moldação.'
              label='2k Spritzguss'
              path='/productsDE'
            />
            <CardItem
              src='images/AstheticParts.png'
              // text='Com uma vasta experiência na injeção e controlo de peças de aspeto, sejam elas de Aspeto Final, para processos de  Pintura ou Cromagem.'
              label='Sichtteile'
              path='/productsDE'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/TranslucidParts.png'
              // text='Temos experiência na injeção de peças translúcidas, sejam elas para fins de guiamentos de Luz, óticos, médicos, entre outros…'
              label='Transparente Teile'
              path='/productsDE'
            />
            <CardItem
              src='images/FlexibleParts.png'
              // text='Podemos injetar plásticos flexíveis desde que sejam da famlía dos termo plásticos elastómeros (TPE)'
              label='Elastische Teile'
              path='/productsDE'
            />
            <CardItem
              src='images/Overmolding2.png'
              //text='Apresentamos soluções para a sobre-injeção (ou sobre moldação) de insertos plásticos ou metálicos, podendo ser responsáveis pela injeção do próprio inserto.'
              label='Overmolding'
              path='/productsDE'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
