import React from 'react'
import Contact from '../Contacts/contactDe'
import ContactMail from '../Contacts/contactMailDe'
import HeroContact from '../HeroContactDe';
import Footer from '../../Footer/FooterDe';
import '../../App.css';


function ContactPage () {
    return (
        <div>
           <HeroContact/>
           <Contact/>
           <ContactMail/>
           <Footer />
        </div>
    );
}

export default ContactPage;